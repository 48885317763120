<!--
 * @Description: 按月新增的组件
 * @Author: luocheng
 * @Date: 2022-08-15 17:01:12
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-16 14:47:06
-->
<template>
  <div class="date-button-child">
    <el-popover
      placement="bottom"
      width="300"
      trigger="click"
    >
      <div class="year-week-content">
        <CalendarHeader
          :year="year"
          :headerType="'year'"
          @setQuickDate="getQuickDate"
        ></CalendarHeader>
        <ul class="common-date-button-list common-date-button-list_month">
          <li class="list-item" v-for="item in monthList" :key="item.resultDesc"
            :class="{
              'is-target': item.isTargetMonth,
              'disabled': item.disabled
            }"
            @click="onMonth(item, true)"
          >
            {{ item.isTargetMonth ? '本月' : item.desc }}
          </li>
        </ul>
      </div>
      <el-button slot="reference" class="date-button-btn">按钮</el-button>
    </el-popover>
  </div>
</template>

<script>
import { Popover } from 'element-ui';
import CalendarHeader from '@/custom-component/calendar/child/CalendarHeader';

export default {
  name: 'DateButtonMonth',
  components: {
    'el-popover': Popover,
    CalendarHeader
  },
  props: {
    // 是否允许提前新增
    advance: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      year: new Date().getFullYear(),
      monthList: []
    }
  },
  created() {
    this.initData(true);
  },
  methods: {
    /**
     * @desc: 获取数据
     * @param {Boolean} isInit
     */
    initData(isInit = false) {
      this.monthList = [];
      const now = new Date();
      if (isInit) {
        this.year = now.getFullYear();
        this.currentMonth = {
          year: this.year,
          month: now.getMonth() + 1,
          date: now.getDate(),
          desc: `${this.year}年${now.getMonth() + 1}月`,
          startTime: `${this.year}-${now.getMonth() + 1}-1 00:00:00`
        }
        this.onMonth(this.currentMonth ,false)
      }
      // 年度结束时间
      for (let i = 1; i < 13; i++) {
        this.monthList.push({
          isTargetMonth: this.isTargetMonth(this.year, i),
          year: this.year,
          month: i,
          date: 1,
          desc: `${i}月`,
          resultDesc: `${this.year}年${i}月`,
          startTime: `${this.year}-${i}-1 00:00:00`,
          disabled: this.toggleDisabled(i)
        });
      }
    },
    /**
     * @desc: 判断是否禁用
     * @param {Number} toMonth
     */
    toggleDisabled(toMonth) {
      if (this.advance) return false;
      const {year, month } = this.currentMonth;
      return this.year > year || (this.year === year && toMonth > month) 
    },
    /**
     * @desc: 选择周
     * @return {Object} month 选择的周对象
     */
    onMonth(data, isUserAction) {
      if (!this.advance && data.disabled) return;
      this.$emit('select', {
        data,
        isUserAction
      });
      if (isUserAction) {
        try {
          const popoverList = document.querySelectorAll('.el-popover');
          console.log(popoverList, '---popoverList');
          popoverList.forEach(ele => {
            ele.style.display = 'none';
          })
        } catch (e) {
          console.log('关闭失败', e);
        }
      }
    },
    /**
     * @desc: 是否为当前周
     */
    isTargetMonth(cYear, cMonth) {
      const { year, month } = this.currentMonth;
      return year === cYear && cMonth === month;
    },
    /**
     * @desc: 快捷切换日期
     * @param {Object} dateObj
     */
    getQuickDate(dateObj) {
      this.year = dateObj.year;
      this.initData();
    }
  }
}
</script>

<style lang="less" scoped>
</style>

