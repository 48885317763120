<!--
 * @Description: 基础模型组件
 * @Author: luocheng
 * @Date: 2021-10-14 09:39:11
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-29 11:56:21
-->
<template>
	<div style="position: relative">
		<div v-show="show" class="monitor-sign" :style="{borderColor:element.monitorConfig.signCol}" >
			<Player v-if="showDetail"  ref="playerRef" @closeMonitor="showDetail = false" :playerType="element.monitorConfig.type" :playerItem="element.monitorConfig" />
			<i @click.stop="showMonitor" :style="{color:element.monitorConfig.signCol}" class="iconfont iconshexiangtou" ></i>
			<span class="trangle" :style="{borderTopColor:element.monitorConfig.signCol}"></span>
		</div>
		<div class="mask" v-if="$route.path === '/modify-page' && showToggle"></div>
		<!-- 遮罩 -->
		<a href="javascript:;" class="mask-btn" v-if="$route.path === '/modify-page'" @click="onToggle">{{ showToggle ? '隐藏遮罩' : '显示遮罩' }}</a>
	</div>
</template>

<script>
import eventBus from '@/plugins/eventBus';
import Player from '@/custom-component/model/components/player.vue'
// import { dataInterface } from '@/apis/data';
import { mapState } from 'vuex';
import { initParams } from '@/utils/tools';
export default {
  name: 'CommonMonitorSign',
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
  },
	components: {
		Player,
	},
  data() {
    return {
			showDetail:false,
			showToggle:true,
			show: true,
			playerType:'ys7',
    }
  },
  computed: {
    ...mapState(['componentData']),
    // 模型配置
    monitorConfig() {
      return this.element && this.element.monitorConfig;
    },
  },
  mounted() {
		this.$nextTick(() => {
      eventBus.$on('doComponentBehavior', config => {
        const { component, list = [] } = config;
        if (component !== this.element.id) return false;
        list.forEach(ele => {
          const { behaviors, params } = ele;
					const { param = {}, canPost } = initParams(params, this.isGroup, this.componentList || this.componentData, this.groupComponents);
          if (canPost) {
            // 调用行为方法
            behaviors.forEach(funName => {
              try {
                eval(this[funName])(param)
              } catch (err) {
                console.log(err);
              }
            });
          }
        });
      })
    });
  },
  updated() {
  },
  methods: {
		//切换遮罩
		onToggle() {
			this.showToggle = !this.showToggle;
		},
		//打开查看监控
		showMonitor(){
			console.log(this.element.monitorConfig)
			if(this.showDetail){
				this.showDetail = false;
			}else{
				if(this.element?.monitorConfig?.token ===''){
					this.$message.warning('参数不全，请联系管理员！')
					return
				}
				this.showDetail = true;
			}
		},
		//显示监控标记组件
		showMonitorSigns(data){
			let obj = data?.key || {}
			if(obj.type === "monitor"){
				this.show = obj.show
			}
		}
  },
  beforeDestroy() {
    eventBus.$off('doComponentBehavior');
  }
};
</script>

<style lang="less" scoped>
	.monitor-sign{
		width: 32px;
		height: 32px;
		background: #fff;
		border-width: 4px;
		border-style: solid;
		border-radius: 50%;
		z-index: 10;
		margin:auto;
		position:relative;
		i{
			font-size: 30px;
			z-index: 10;
		}
		.trangle{
			width: 0px;
			height: 0px;
			border-width: 12px;
			border-style: solid;
			border-bottom-color: transparent;
			border-left-color: transparent;
			border-right-color: transparent;
			position: absolute;
			bottom: -24px;
			left: 4px;
			z-index: 10;
		}
	}
	.player-cont{
			position: absolute;
			width: 250px;
			height: 150px;
			top: -165px;
      left: -110px;
			z-index: 20;
			.close{
				top: 2px;
				right: 5px;
				font-size: 22px;
				color:#fff;
			}
			.full-screen{
				font-size: 15px;
				top: 5px;
				right:30px;
				color:#fff;
			}
	}
	.mask-btn{
		position: absolute;
		padding: 5px;
		font-size: 14px;
		z-index: 30;
		color: @theme;
		right: 0;
		top: 0;
		cursor: pointer;
	}
	.mask{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: transparent;
		line-height: 100%;
		color: #fff;
		z-index: 20;
	}
</style>