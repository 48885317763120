<!--
 * @Description: 年选择器
 * @Author: luocheng
 * @Date: 2022-08-15 17:01:12
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-16 15:09:18
-->
<template>
  <div class="date-button-child">
    <el-popover
      placement="bottom"
      width="300"
      trigger="click"
    >
      <div class="year-week-content">
        <h3 class="just-title">请选择年</h3>
        <ul class="common-date-button-list">
          <li class="list-item" v-for="item in yearList" :key="item.resultDesc"
            :class="{
              'is-target': item.isTargetYear,
              'disabled': item.disabled
            }"
            @click="onYear(item, true)"
          >
            {{ item.isTargetYear ? '本年' : item.desc }}
          </li>
        </ul>
      </div>
      <el-button slot="reference" class="date-button-btn">按钮</el-button>
    </el-popover>
  </div>
</template>

<script>
import { Popover } from 'element-ui';

export default {
  name: 'DateButtonYear',
  components: {
    'el-popover': Popover
  },
  props: {
    // 是否允许提前新增
    advance: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      year: new Date().getFullYear(),
      yearList: []
    }
  },
  created() {
    this.initData(true);
  },
  methods: {
    /**
     * @desc: 获取数据
     * @param {Boolean} isInit
     */
    initData(isInit = false) {
      this.yearList = [];
      const now = new Date();
      if (isInit) {
        this.year = now.getFullYear();
        this.currentYear = {
          year: this.year,
          month: 1,
          date: 1,
          desc: `${this.year}年`,
          startTime: `${this.year}-1-1 00:00:00`
        }
        this.onYear(this.currentYear, false)
      }
      for (let i = 0; i < 8; i++) {
        this.yearList.unshift({
          isTargetYear: this.isTargetYear(this.year - i),
          year: this.year - i,
          month: 1,
          date: 1,
          desc: `${this.year - i}年`,
          resultDesc: `${this.year - i}年`,
          startTime: `${this.year - i}-1-1 00:00:00`,
          disabled: this.toggleDisable(this.year - i)
        });
      }
      for (let i = 1; i < 8; i++) {
        this.yearList.push({
          isTargetYear: this.isTargetYear(this.year + i),
          year: this.year + i,
          month: 1,
          date: 1,
          desc: `${this.year + i}年`,
          resultDesc: `${this.year + i}年`,
          startTime: `${this.year + i}-1-1 00:00:00`,
          disabled: this.toggleDisable(this.year + i)
        });
      }
    },
    /**
     * @desc: 是否禁用
     * @param {Number} year
     */
    toggleDisable(year) {
      if (this.advance) return false;
      return year > this.currentYear.year;
    },
    /**
     * @desc: 选择周
     * @return {Object} month 选择的周对象
     */
    onYear(data, isUserAction) {
      if (!this.advance && data.disabled) return false;
      this.$emit('select', {
        data,
        isUserAction
      });
      if (isUserAction) {
        try {
          const popoverList = document.querySelectorAll('.el-popover');
          popoverList.forEach(ele => {
            ele.style.display = 'none';
          })
        } catch (e) {
          console.log('关闭失败', e);
        }
      }
    },
    /**
     * @desc: 是否为当前年
     */
    isTargetYear(cYear) {
      const { year } = this.currentYear;
      return year === cYear;
    }
  }
}
</script>

<style lang="less" scoped>
</style>

