<template>
  <div class="decoration-container">
    <div 
      class="ground-glass-effect" 
      :style="`background: ${ backgroundColor };backdrop-filter: blur(${blur || 6 }px);`"
    >
    </div>
  </div>
</template>
<script>
import Minin from './mixin';
export default {
  name: 'Border15',
  mixins: [Minin],
  data() {
    return {
      ref: 'borderBox-15'
    }
  },
  computed: {
    backgroundColor() {
      return this.statusConfig.backgroundColor || 'RGBA(255, 255, 255, .2)';
    },
    blur() {
      return this.statusConfig.blur || 6;
    }
  }
}
</script>
<style lang="less" scoped>
  .decoration-container{
    width: 100%;
    height: 100%;
    .ground-glass-effect{
      width: 100%;
      height: 100%;
      backdrop-filter: blur(6px);
    }
  }
</style>