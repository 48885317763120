<!--
 * @Description: 按周新增的组件
 * @Author: luocheng
 * @Date: 2022-08-15 17:01:12
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-16 14:42:19
-->
<template>
  <div class="date-button-child">
    <el-popover
      placement="bottom"
      width="300"
      trigger="click"
    >
      <div class="year-week-content">
        <CalendarHeader
          :year="year"
          :headerType="'year'"
          @setQuickDate="getQuickDate"
        ></CalendarHeader>
        <ul class="common-date-button-list">
          <li class="list-item" v-for="(item, index) in weekList" :key="item.date"
            :id="'common-date-button-list-item_' + index"
            :class="{
              'is-target': item.isTargetWeek,
              'disabled': item.disabled
            }"
            @click="onWeek(item, true)"
          >
            {{ item.isTargetWeek ? '本周' : item.desc }}
          </li>
        </ul>
      </div>
      <el-button slot="reference" class="date-button-btn" @click="onButton">按钮</el-button>
    </el-popover>
  </div>
</template>

<script>
import { Popover } from 'element-ui';
import CalendarHeader from '@/custom-component/calendar/child/CalendarHeader';
import { formatTime } from '@/utils/tools';

export default {
  name: 'YearWeek',
  components: {
    'el-popover': Popover,
    CalendarHeader
  },
  props: {
    // 是否允许提前新增
    advance: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      year: new Date().getFullYear(),
      weekList: [],
      targetIndex: 0
    }
  },
  created() {
    this.initData(true);
  },
  methods: {
    /**
     * @desc: 获取数据
     * @param {Boolean} isInit
     */
    initData(isInit = false) {
      this.weekList = [];
      const now = new Date();
      if (isInit) {
        this.year = now.getFullYear();
        const during = this.getWeekDuring(this.year, now.getMonth() + 1, now.getDate());
        this.currentWeek = {
          year: this.year,
          month: now.getMonth() + 1,
          date: now.getDate(),
          desc: during.join(' '),
          startTime: `${during[0]} 00:00:00`,
          during
        }
        this.onWeek(this.currentWeek, false)
      }
      const firstDay = new Date(`${this.year}-01-01`).getDay() || 7;
      let startObj = new Date(`${this.year}-01-01 00:00:00`);
      if (firstDay !== 1) {
        // 非周一
        startObj = new Date(`${this.year}-01-${7 - firstDay + 2} 00:00:00`)
      }
      let startYear = now.getFullYear();
      // 年度结束时间
      const endTime = new Date(`${this.year + 1}-01-01 00:00:00`);
      let currentDate = null;
      let weekEndTime = null;
      for (let i = 0; i < 80; i++) {
        currentDate = new Date(startObj.getTime() + i * 7 * 86400000);
        weekEndTime = currentDate.getTime() + 7 * 86400000;
        if (weekEndTime > endTime) {
          // 超过本年
          startYear = startYear + 1;
        }
        const during = this.getWeekDuring(this.year, currentDate.getMonth() + 1, currentDate.getDate())
        const isTargetWeek = this.isTargetWeek(`${during[0]} 00:00:00`);
        this.weekList.push({
          isTargetWeek,
          year: this.year,
          month: currentDate.getMonth() + 1,
          desc: `第${i + 1}周`,
          resultDesc: `${during.join(' ')} 第${i + 1}周`,
          startTime: `${during[0]} 00:00:00`,
          disabled: this.toggleAdvance(during[0])
        });
        if (isTargetWeek) {
          this.targetIndex = i;
        }
        if (weekEndTime > new Date(`${this.year}-12-31 23:59:59`).getTime()) {
          break;
        }
      }
    },
    onButton() {
      // 滚动到本周
      setTimeout(() => {
        this.$nextTick(() => {
          try {
            document.querySelector(`#common-date-button-list-item_${this.targetIndex}`)?.scrollIntoView();
          } catch (err) {
            console.log(err, '---滚动失效')
          }
        });
      }, 0);
    },
    /**
     * @desc: 选择周
     * @return {Object} weekObj 选择的周对象
     */
    onWeek(weekObj, isUserAction) {
      if (!this.advance && weekObj.disabled) return false;
      this.$emit('select', {
        data: weekObj,
        isUserAction
      });
      if (isUserAction) {
        try {
          const popoverList = document.querySelectorAll('.el-popover');
          popoverList.forEach(ele => {
            ele.style.display = 'none';
          })
        } catch (e) {
          console.log('关闭失败', e);
        }
      }
    },
    /**
     * @desc: 获取一周的开始结束时间段
     */
    getWeekDuring(year, month, date) {
      const dateObj = new Date(`${year}-${month}-${date} 00:00:00`);
      const day = dateObj.getDay() || 7;
      const startTime = dateObj.getTime() - (day - 1) * 86400000;
      const endTime = dateObj.getTime() + (7 - day + 1) * 86400000 - 1000;
      return [formatTime(startTime), formatTime(endTime)];
    },
    /**
     * @desc: 是否为当前周
     */
    isTargetWeek(startTime) {
      return startTime.includes(this.currentWeek?.during?.[0] || 'valid');
    },
    /**
     * @desc: 判断是否可以提前新增
     * @param {String} startTime
     */
    toggleAdvance(startTime) {
      if (this.advance) return false;
      const { during } =  this.currentWeek;
      return new Date(startTime) > new Date(during[1]);
    },
    /**
     * @desc: 快捷切换日期
     * @param {Object} dateObj
     */
    getQuickDate(dateObj) {
      this.year = dateObj.year;
      this.initData();
    }
  }
}
</script>

<style lang="less" scoped>

</style>

