<template>
  <dv-decoration-8 class="decoration-container" :reverse="reverse" :color="color"></dv-decoration-8>
</template>
<script>
import { decoration8 } from '@jiaminghi/data-view'
import Vue from 'vue'
import Minin from './mixin'
Vue.use(decoration8)
export default {
  name: 'Decoration8',
  mixins: [Minin]
}
</script>
<style lang="less" scoped>
  .decoration-container{
    width: 100%;
    height: 100%;
  }
</style>