<template>
  <dv-decoration-4 class="decoration-container" :reverse="reverse" :color="color" :dur="dur"></dv-decoration-4>
</template>
<script>
import { decoration4 } from '@jiaminghi/data-view'
import Vue from 'vue'
import Minin from './mixin'
Vue.use(decoration4)
export default {
  name: 'Decoration4',
  mixins: [Minin]
}
</script>
<style lang="less" scoped>
  .decoration-container{
    width: 100%;
    height: 100%;
  }
</style>