<!--
    @name: widget-qrCode
    @description：widget-qrCode
    @author: ZengWei
    @date: 2022-04-11 18:29
-->
<template>
  <div v-loading="loading">
    <el-button v-if="!(disabled || element.config.disabled)" slot="reference" @click="generateQrcode">
      <i class="iconfont iconerweima"></i>&nbsp;&nbsp;&nbsp;生成二维码
    </el-button>
    <el-image
      v-else
      :src="element.value"
      style="width: 100px; height: 100px"
      :preview-src-list="[element.value]"
    >
    </el-image>
  </div>
</template>

<script>
import {Image} from "element-ui";
import { formRequest } from "@/apis/data/form";

export default {
  name: "WidgetQrCode",
  components: {
    'el-image': Image
  },
  props: ['element','disabled','displayData'],
  inject:['parser'],
  data() {
    return {
      loading:false,
      qrcode:'',
    }
  },
  methods: {
    generateQrcode(){
      this.loading = true;
      const formId = this.element.config.__config__.paramFormId
      const value = this.parser.getFormIdValue(formId)
      const funcName = this.element.config.__config__.serverFunc
      const qrCodeParam = this.element.config.__config__.serverParams

      const params = {
        margin: qrCodeParam[0].value,
        str: qrCodeParam[1].value + value,
      }
      const data = {
        function: funcName,
        params: params
      }
      const url = '/api/form4/funcDataList'
      formRequest('post', url ,data)
        .then(res=>{
          if (res.data.code === 200) {
            this.element.value = res.data.data
          }
          this.loading = false;
        })
    },
  },
}
</script>

<style scoped>

</style>
