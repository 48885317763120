<!--
    @name: ques-radio
    @description：ques-radio
    @author: ZengWei
    @date: 2022-04-01 11:17
-->
<template>
  <div class="widget-text">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="seq-num">
        <span v-if="element.config.__config__.required" class="required">*</span>
      </div>
      <div class="q-title">
        {{ element.config.__config__.label }}
      </div>
      <div class="q-title" v-html="element.config.__ques__.quesDesc"></div>
    </div>
  </div>
</template>

<script>
import quesWidgetMinix from "@/custom-component/form/quesParser/scripts/quesWidgetMinix";

export default {
  name: "ques-text",
  components: {},
  mixins:[quesWidgetMinix],
  props: ['element','number'],
  computed:{},
  data() {
    return {

    }
  },
  methods: {},
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/questionItem';
</style>
