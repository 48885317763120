<!--
 * @Description: 配置数据
 * @Author: luocheng
 * @Date: 2022-02-12 14:38:01
 * @LastEditors: luocheng
 * @LastEditTime: 2022-03-17 18:27:55
-->
<template>
	<div class="static-count">({{ count }})</div>
</template>

<script>
import { dataInterface } from '@/apis/data';

export default {
	name: 'StaticCount',
	props: {
		config: {
			type: [Object, null],
			default: () => null,
			required: false
		}
	},
	data() {
		return {
			count: 0
		};
	},
	created() {
		this.getCount();
	},
	methods: {
		// 获取统计数据
		getCount() {
			const data = {};
			this.$set(data, this.config.key, {
				__method_name__: 'globalFunctionCall',
				typeName: 'PublicFunc',
				type: 'value',
				funcName: 'ViewAggregate',
				payload: {
					field_uuid: this.config.field_uuid,
					view_uuid: this.config.view_uuid,
					aggregate: this.config.aggregate
				},
				search: [],
				param: {}
			});
			dataInterface({
				__method_name__: 'moreAction',
				data
			}).then((res) => {
				if (res.status === 200) {
					this.count = res.data.data.count || 0;
				}
			}).catch((err) => {
				console.log(err, '----获取tabs统计失败！！！！');
			});
		}
	}
};
</script>

<style lang="less" scoped>
.static-count {
	height: 100%;
	line-height: 22px;
	font-weight: 400;
	font-family: PingFangSC-Regular, PingFang SC;
}
</style>