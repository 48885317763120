export default {
  categoryFuncList: [
    {
      category: '逻辑函数',
      functions: [
        {
          ruleType: 'FUNCTION',
          displayName: 'IF',
          funcName: 'calcIF',
          backFuncName: 'if',
          category: '逻辑函数',
          wiki: 'IF(条件,A,B) ：如果满足条件，默认返回TRUE，否则返回FALSE，A/B可替换为其他输入值或事件，支持嵌套多层函数。（不支持表格内字段的逻辑处理）',
          desc: '根据条件执行对应事件',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'IF',
              funcName: 'calcIF',
              backFuncName: 'if'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'EMPTY',
              displayName: ' ',
              funcName: '空'
            },
            {
              ruleType: 'OPERATION',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'STATIC',
              displayName: 'TRUE',
              funcName: 'TRUE',
              value: 'true',
              paramType: 'bool'
            },
            {
              ruleType: 'OPERATION',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'STATIC',
              displayName: 'FALSE',
              funcName: 'FALSE',
              value: 'false',
              paramType: 'bool'
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        }
        /* {
          "ruleType": "EXPRESS_FUNCTION",
          "displayName": "AND",
          "funcName": "&&",
          "backFuncName": "&",
          "category": "逻辑函数",
          "wiki": "(条件1)AND(条件2)：多个用AND连接的条件，当所有条件均满足时，表达式返回TRUE，否则返回FALSE，支持连接多个条件。（不支持表格内字段的逻辑处理）",
          "desc": "若干个条件同时满足，逻辑才成立",
          "defaultFuncList": [
            {
              "ruleType": "OPERATION",
              "displayName": "(",
              "funcName": "("
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "OPERATION",
              "displayName": ")",
              "funcName": ")"
            },
            {
              "ruleType": "EXPRESS_FUNCTION",
              "displayName": "AND",
              "funcName": "&&",
              "backFuncName": "&"
            },
            {
              "ruleType": "OPERATION",
              "displayName": "(",
              "funcName": "("
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "OPERATION",
              "displayName": ")",
              "funcName": ")"
            }
          ]
        },
        {
          "ruleType": "EXPRESS_FUNCTION",
          "displayName": "OR",
          "funcName": "||",
          "backFuncName": "|",
          "category": "逻辑函数",
          "wiki": "(条件1)OR(条件2)：多个用OR连接的条件，只要有一个条件满足时，表达式返回TRUE，否则返回FALSE，支持连接多个条件。（不支持表格内字段的逻辑处理）",
          "desc": "若干个条件只要有一个满足，逻辑即成立",
          "defaultFuncList": [
            {
              "ruleType": "OPERATION",
              "displayName": "(",
              "funcName": "("
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "OPERATION",
              "displayName": ")",
              "funcName": ")"
            },
            {
              "ruleType": "EXPRESS_FUNCTION",
              "displayName": "OR",
              "funcName": "||",
              "backFuncName": "|"
            },
            {
              "ruleType": "OPERATION",
              "displayName": "(",
              "funcName": "("
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "OPERATION",
              "displayName": ")",
              "funcName": ")"
            }
          ]
        },
        {
          "ruleType": "EXPRESS_FUNCTION",
          "displayName": "NOT",
          "funcName": "!",
          "category": "逻辑函数",
          "wiki": "NOT(条件)：对条件的逻辑值取反，例：NOT(2>1)的返回就是FALSE。（不支持表格内字段的逻辑处理）",
          "desc": "对条件的逻辑值取反",
          "defaultFuncList": [
            {
              "ruleType": "EXPRESS_FUNCTION",
              "displayName": "NOT",
              "funcName": "!"
            },
            {
              "ruleType": "OPERATION",
              "displayName": "(",
              "funcName": "("
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "OPERATION",
              "displayName": ")",
              "funcName": ")"
            }
          ]
        },
        {
          "ruleType": "FUNCTION",
          "displayName": "ISEMPTY",
          "funcName": "calcEMPTY",
          "category": "逻辑函数",
          "wiki": "ISEMPTY(字段) ：如果是空值，返回TRUE，否则返回FALSE。（不支持表格内字段的逻辑处理）",
          "desc": "判断字段是否为空",
          "defaultFuncList": [
            {
              "ruleType": "FUNCTION",
              "displayName": "ISEMPTY",
              "funcName": "calcEMPTY"
            },
            {
              "ruleType": "OPERATION",
              "displayName": "(",
              "funcName": "("
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "OPERATION",
              "displayName": ")",
              "funcName": ")"
            }
          ]
        },
        {
          "ruleType": "FUNCTION",
          "displayName": "ISCONTAINS",
          "funcName": "calcCONTSINS",
          "category": "逻辑函数",
          "wiki": "ISCONTAINS(字段1，字段2) ：如果字段1包含字段2，表达式返回TRUE，否则返回FALSE。（不支持表格内字段的逻辑处理）",
          "desc": "判断前一个字段是否包含后一个字段",
          "defaultFuncList": [
            {
              "ruleType": "FUNCTION",
              "displayName": "ISCONTAINS",
              "funcName": "calcCONTSINS"
            },
            {
              "ruleType": "OPERATION",
              "displayName": "(",
              "funcName": "("
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "OPERATION",
              "displayName": ",",
              "funcName": ","
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "OPERATION",
              "displayName": ")",
              "funcName": ")"
            }
          ]
        },
        {
          "ruleType": "FUNCTION",
          "displayName": "EQUAL",
          "funcName": "calcEAQUAL",
          "category": "逻辑函数",
          "wiki": " EQUAL(字段1，字段2)：如果字段1和字段2相等，返回TRUE，否则返回FALSE。（不支持表格内字段的逻辑处理）",
          "desc": "判断两个字符串是否相等",
          "defaultFuncList": [
            {
              "ruleType": "FUNCTION",
              "displayName": "EQUAL",
              "funcName": "calcEAQUAL"
            },
            {
              "ruleType": "OPERATION",
              "displayName": "(",
              "funcName": "("
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "OPERATION",
              "displayName": ",",
              "funcName": ","
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "OPERATION",
              "displayName": ")",
              "funcName": ")"
            }
          ]
        } */
      ]
    },
    {
      category: '数学函数',
      functions: [
        {
          ruleType: 'FUNCTION',
          displayName: 'MULTIPLY',
          funcName: 'calcMULTIPLY',
          category: '数学函数',
          wiki: 'MULTIPLY(字段1, 字段2, …) ：仅支持对数字、金额类型字段的求乘积。',
          desc: '多字段求乘积',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'MULTIPLY',
              funcName: 'calcMULTIPLY'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'EMPTY',
              displayName: ' ',
              funcName: '空'
            },
            {
              ruleType: 'SEPARATOR',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'EMPTY',
              displayName: ' ',
              funcName: '空'
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'SUM',
          funcName: 'calcSUM',
          category: '数学函数',
          wiki: 'SUM(字段1, 字段2, …) ：仅支持对数字、金额类型字段的求和。',
          desc: '多字段求和',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'SUM',
              funcName: 'calcSUM'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'EMPTY',
              displayName: ' ',
              funcName: '空'
            },
            {
              ruleType: 'SEPARATOR',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'EMPTY',
              displayName: ' ',
              funcName: '空'
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        }
        /* {
          "ruleType": "FUNCTION",
          "displayName": "AVG",
          "funcName": "calcAVG",
          "category": "数学函数",
          "wiki": "AVG(字段1, 字段2, …) ：仅支持返回数字、金额类型字段的平均值。",
          "desc": "多字段求平均值",
          "defaultFuncList": [
            {
              "ruleType": "FUNCTION",
              "displayName": "AVG",
              "funcName": "calcAVG"
            },
            {
              "ruleType": "OPERATION",
              "displayName": "(",
              "funcName": "("
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "SEPARATOR",
              "displayName": ",",
              "funcName": ","
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "OPERATION",
              "displayName": ")",
              "funcName": ")"
            }
          ]
        },
        {
          "ruleType": "FUNCTION",
          "displayName": "MAX",
          "funcName": "calcMAX",
          "category": "数学函数",
          "wiki": "MAX(字段1, 字段2, …) ：仅支持返回数字、金额类型字段的最大值。",
          "desc": "多字段取最大值",
          "defaultFuncList": [
            {
              "ruleType": "FUNCTION",
              "displayName": "MAX",
              "funcName": "calcMAX"
            },
            {
              "ruleType": "OPERATION",
              "displayName": "(",
              "funcName": "("
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "SEPARATOR",
              "displayName": ",",
              "funcName": ","
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "OPERATION",
              "displayName": ")",
              "funcName": ")"
            }
          ]
        },
        {
          "ruleType": "FUNCTION",
          "displayName": "MIN",
          "funcName": "calcMIN",
          "category": "数学函数",
          "wiki": "MIN(字段1, 字段2, …) ：仅支持返回数字、金额类型字段的最小值。",
          "desc": "多字段取最小值",
          "defaultFuncList": [
            {
              "ruleType": "FUNCTION",
              "displayName": "MIN",
              "funcName": "calcMIN"
            },
            {
              "ruleType": "OPERATION",
              "displayName": "(",
              "funcName": "("
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "SEPARATOR",
              "displayName": ",",
              "funcName": ","
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "OPERATION",
              "displayName": ")",
              "funcName": ")"
            }
          ]
        },
        {
          "ruleType": "FUNCTION",
          "displayName": "COUNT",
          "funcName": "calcCOUNT",
          "category": "数学函数",
          "wiki": "COUNT(字段)：用于统计表格内字段非空数据条数。",
          "desc": "统计表格字段数据条数",
          "defaultFuncList": [
            {
              "ruleType": "FUNCTION",
              "displayName": "COUNT",
              "funcName": "calcCOUNT"
            },
            {
              "ruleType": "OPERATION",
              "displayName": "(",
              "funcName": "("
            },
            {
              "ruleType": "EMPTY",
              "displayName": " ",
              "funcName": "空"
            },
            {
              "ruleType": "OPERATION",
              "displayName": ")",
              "funcName": ")"
            }
          ]
        } */
      ]
    }
  ],
  expressBarList: [
    {
      ruleType: 'OPERATION',
      displayName: '+',
      funcName: '+'
    },
    {
      ruleType: 'OPERATION',
      displayName: '-',
      funcName: '-'
    },
    {
      ruleType: 'OPERATION',
      displayName: '*',
      funcName: '*'
    },
    {
      ruleType: 'OPERATION',
      displayName: '/',
      funcName: '/'
    },
    {
      ruleType: 'OPERATION',
      displayName: '>',
      funcName: '>'
    },
    {
      ruleType: 'OPERATION',
      displayName: '>=',
      funcName: '>='
    },
    {
      ruleType: 'OPERATION',
      displayName: '<',
      funcName: '<'
    },
    {
      ruleType: 'OPERATION',
      displayName: '<=',
      funcName: '<='
    },
    {
      ruleType: 'OPERATION',
      displayName: '=',
      funcName: '=='
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'STATIC',
      displayName: '输入值'
    },
    {
      ruleType: 'STATIC',
      displayName: 'TRUE',
      value: 'true',
      paramType: 'bool'
    },
    {
      ruleType: 'STATIC',
      displayName: 'FALSE',
      value: 'false',
      paramType: 'bool'
    }
  ],
  advancedRuleList: [
    {
      ruleType: 'FUNCTION',
      displayName: 'IF',
      funcName: 'calcIF',
      backFuncName: 'if'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'FUNCTION',
      displayName: 'SUM',
      funcName: 'calcSUM'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'L',
      value: '2f18780d-3454-4bec-8759-8e9d708b1e99'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'E',
      value: 'c06f6292-346f-4fe6-b4d5-fed63324200b'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'C',
      value: '89556302-0139-441f-b74c-b03dce63f406'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'OPERATION',
      displayName: '<',
      funcName: '<'
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '70',
      paramType: 'number'
    },
    {
      ruleType: 'OPERATION',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '较小风险',
      paramType: 'string'
    },
    {
      ruleType: 'OPERATION',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'FUNCTION',
      displayName: 'IF',
      funcName: 'calcIF',
      backFuncName: 'if'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'FUNCTION',
      displayName: 'SUM',
      funcName: 'calcSUM'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'L',
      value: '2f18780d-3454-4bec-8759-8e9d708b1e99'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'E',
      value: 'c06f6292-346f-4fe6-b4d5-fed63324200b'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'C',
      value: '89556302-0139-441f-b74c-b03dce63f406'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'OPERATION',
      displayName: '<',
      funcName: '<'
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '160',
      paramType: 'number'
    },
    {
      ruleType: 'OPERATION',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '一般风险',
      paramType: 'string'
    },
    {
      ruleType: 'OPERATION',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'FUNCTION',
      displayName: 'IF',
      funcName: 'calcIF',
      backFuncName: 'if'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'FUNCTION',
      displayName: 'SUM',
      funcName: 'calcSUM'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'L',
      value: '2f18780d-3454-4bec-8759-8e9d708b1e99'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'E',
      value: 'c06f6292-346f-4fe6-b4d5-fed63324200b'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'C',
      value: '89556302-0139-441f-b74c-b03dce63f406'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'OPERATION',
      displayName: '<',
      funcName: '<'
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '320',
      paramType: 'number'
    },
    {
      ruleType: 'OPERATION',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '较大风险',
      paramType: 'string'
    },
    {
      ruleType: 'OPERATION',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'FUNCTION',
      displayName: 'IF',
      funcName: 'calcIF',
      backFuncName: 'if'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'FUNCTION',
      displayName: 'SUM',
      funcName: 'calcSUM'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'L',
      value: '2f18780d-3454-4bec-8759-8e9d708b1e99'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'E',
      value: 'c06f6292-346f-4fe6-b4d5-fed63324200b'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'C',
      value: '89556302-0139-441f-b74c-b03dce63f406'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'OPERATION',
      displayName: '>=',
      funcName: '>='
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '320',
      paramType: 'number'
    },
    {
      ruleType: 'OPERATION',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '重大风险',
      paramType: 'string'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    }
  ]
}
