/*
 * @Description: 配置
 * @Author: luocheng
 * @Date: 2021-09-01 16:26:44
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-09-29 17:53:27
 */
// 直接在慈溪浒进行配置
const env = {
  devURL: 'https://saas-api.bimcc.net/',
  onlineURL: 'https://saas-api.bimcc.net/',
  NODE_ENV: 'development'
}

export default {
  /**
   * @description token在Cookie中存储的天数，默认1天
   */
  cookieExpires: 1,
  /**
   * @description 是否使用国际化，默认为false
   *              如果不使用，则需要在路由中给需要在菜单中展示的路由设置meta: {title: 'xxx'}
   *              用来在菜单中显示文字
   */
  useI18n: false,
  /**
   * @description api请求基础路径
   */
  devServer: {
    proxy: 'http://mainapi.com/'
  },
  baseUrl: {
    dev: env.devURL ? env.devURL : 'https://saas-api.bimcc.net/',
    pro: env.onlineURL ? env.onlineURL : 'https://saas-api.bimcc.net/'
  },
  realUrl: process.env.NODE_ENV === 'development' ? env.devURL : env.onlineURL,
  /**
   * @description 默认打开的首页的路由name值，默认为home
   */
  homeName: 'home',
  /**
   * @description 需要加载的插件
   */
  plugin: {
    // 'error-store': {
    //   showInHeader: true, // 设为false后不会在顶部显示错误日志徽标
    //   developmentOff: false // 设为true后在开发环境不会收集错误信息，方便开发中排查错误
    // }
  }
}
