<!--
    @name: ques-textarea
    @description：ques-textarea
    @author: ZengWei
    @date: 2022-04-01 11:19
-->
<template>
  <div class="widget-textarea">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="seq-num">
        <span v-if="element.config.__config__.required" class="required">*</span>
      </div>
      <div class="q-title">
        <span class="ques-type">[问答题]</span>
        {{ element.config.__config__.label }}
      </div>
      <div
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc"
        class="q-title">
      </div>
    </div>
    <div class="q-option-list">
      <div class="q-option-ul">
        <el-input v-model="element.value" type="textarea" :rows="6" placeholder=""></el-input>
      </div>
    </div>
  </div>
</template>

<script>
import {Input} from 'element-ui'

export default {
  name: "ques-textarea",
  components: {
    'el-input':Input
  },
  props: ['element','number'],
  data() {
    return {}
  },
  methods: {
    calcScore(value){
      const config = this.element.config.__ques__
      const keyword = config.answer.split(';'); //答案关键词
      let score = 0
      if(config.scoreType === 1 && config.scoreTypeTwo === 1){
        // 包含关键词得分，答出几个得几分
        for (const item of keyword){
          if(value.indexOf(item)){
            score += parseFloat(config.optScore)
          }
        }
      } else if(config.scoreType === 1 && config.scoreTypeTwo === 2){
        // 包含关键词得分，答出全部得分
        let number = 0
        for (const item of keyword){
          if(value.indexOf(item)){
            number++
          }
        }
        if(number === keyword.length) {
          score = config.score
        }
      } else if(config.scoreType === 2){
        // 完全相同得分，答出几个得几分
        if(value === config.answer){
          score = config.score
        }
      }
      this.element.score = score
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/questionItem';
</style>
