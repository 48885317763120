<!--
    @name: NodePanel
    @description：NodePanel
    @author: ZengWei
    @date: 2021-10-22 09:06
-->
<template>
  <div class="node-panel">
    <div
      v-for="item in nodeList"
      :key="item.text"
      class="node-item"
      :draggable="true"
      @dragstart="$_dragNode($event, item)"
    >
      <div class="node-item-icon" :class="item.class">
        <div
          v-if="item.type === 'user' || item.type === 'time'"
          class="shape"
        ></div>
      </div>
      <span class="node-label">{{ item.text }}</span>
    </div>
  </div>
</template>

<script>
  export default{
    name: 'NodePanel',
    props: {},
    data() {
      return {
        nodeList: [
          {
            text: '开始',
            type: 'start',
            class: 'node-start',
            icon: 'iconyuanxingweixuanzhong',
            data: {
              text: '开始',
              rect: {
                width: 60,
                height: 60,
              },
              name: 'circle',
              fillStyle: '',
            },
          },
          {
            text: '步骤',
            type: 'rect',
            class: 'node-rect',
            icon: 'iconjuxing',
            data: {
              text: '审批人：流程中指定',
              rect: {
                width: 160,
                height: 68,
              },
              borderRadius: 10,
              name: 'rectangle',
            },
          },
          {
            type: 'end',
            text: '结束',
            class: 'node-end',
            icon: 'icondanxuan_xuanzhong',
            data: {
              text: '',
              rect: {
                width: 60,
                height: 60,
              },
              name: 'activityFinal',
            },
          },
        ],
      }
    },
    methods: {
      $_dragNode(event, item) {
        this.$emit('ondrag', event, item)
      },
    },
  }
</script>

<style lang="less" scoped>
  //默认margin
 @baseMain: 20px;
  .node-panel {
    position: absolute;
    top: 100px;
    left: @baseMain;
    z-index: 101;
    width: 50px;
    padding: 20px 10px;
    text-align: center;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0 10px 1px rgb(228, 224, 219);
  }

  .node-item {
    margin-bottom: 20px;
  }

  .node-item-icon {
    display: block;
    width: 30px;
    height: 30px;
    margin: auto;
    background-size: cover;
  }

  .node-label {
    font-size: 12px;
    line-height: 30px;
    user-select: none;
  }

  .node-start {
    background: url('../background/start.png') no-repeat;
    background-size: cover;
  }

  .node-rect {
    background: url('../background/rectangle.png') no-repeat;
    background-size: cover;
  }

  .node-user {
    background: url('../background/user.png') no-repeat;
    background-size: cover;
  }

  .node-time {
    background: url('../background/time.png') no-repeat;
    background-size: cover;
  }

  .node-push {
    background: url('../background/push.png') no-repeat;
    background-size: cover;
  }

  .node-download {
    background: url('../background/download.png') no-repeat;
    background-size: cover;
  }
  .node-end {
    background: url('../background/end.png') no-repeat;
    background-size: cover;
  }
</style>
