/*
 * @Description: 请求
 * @Author: luocheng
 * @Date: 2021-09-01 16:09:22
 * @LastEditors: luocheng
 * @LastEditTime: 2021-09-06 16:20:23
 */

// let baseUrl = "https://dwh_api.bimcc.net/api/metadata/"
// let metadatatoken = '4fb0b638-2c43-4d7e-8acf-f1a7abdee58e'
// let contentType = 'application/json;charset=UTF-8'
// let timeout = 60000

// let instance = axios.create({
//     baseUrl,
//     timeout: timeout,
//     headers: {
//         'Content-Type': contentType,
//         'metadatatoken' : metadatatoken
//     },
// })

import HttpRequest from './axios'
import config from './config'
export const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro
export const axios = new HttpRequest(baseUrl)