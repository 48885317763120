/* eslint-disable */
// 自定义验证规则
const checkRate = (rule, value, callback) => {
	if ( Object.prototype.toString.call(value) !=='[object Object]') {
		callback(new Error('值类型错误！'));
	} else {
		if (value.rate <=0 || value.comment.length <= 0) {
			callback(new Error('请填写评分评价'));
		} else {
			callback();
		}
	}
};
// 自定义验证规则
const checkVideo = (rule, value, callback) => {
	if (isNaN(value)) {
		callback(new Error('值类型错误！'));
	} else {
		if (Number(value) <= 0) {
			callback(new Error('必须观看视频'));
		} else {
			callback();
		}
	}
};

const formatElement = (ele) => {
	let itemData; //格式化后的item数据
	let recoverVal = '';
	let type = 'string'
	switch (ele.__config__.tagIcon) {
		case 'input':
		case 'textarea':
		case 'rich-text':
		case 'money':
		case 'password':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : '';
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'string', trigger: 'blur'};
			}
			break;
		case 'number':
		case 'slider':
			recoverVal = 0;
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'number', trigger: 'change'};
			}
			break;
		case 'rate':
			recoverVal = { rate: 0, comment: [] };
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = [
					{required: true, message: ele.__config__.label + '为必填项', type: 'object', trigger: 'change'},
					{ validator: checkRate, trigger: 'blur' }
				];
			}
			break;
		case 'video':
			recoverVal = 0;
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = [
					{required: true, message: ele.__config__.label + '为必填项', type: 'number', trigger: 'change'},
					{ validator: checkVideo, trigger: 'blur' }
				];
			}
			break;
		case 'select':
			let defaultVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : '';
			defaultVal += '';
			itemData = {
				value: defaultVal,
				type: ele.__config__.tagIcon,
				field: ele.__vModel__,
				config: ele,
				validate: []
			};
			if (ele.__config__.required) {
				itemData.validate = [{
					required: true, message: ele.__config__.label + '为必填项', type: 'string', trigger: 'change',
					transform: (value) => {
						let base;
						if (value) base = "" + value;
						return base;
					}
				}];
			}
			if (ele.multiple) {
				defaultVal = [];
				itemData.value = defaultVal
				if (ele.__config__.required) {
					itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'}
				}
			}

			break;
		case 'radio':
		case 'color':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue + '' : '';
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = {
					required: true, type: 'string', message: ele.__config__.label + '为必填项',
					trigger: 'change', transform: (value) => {
						let base;
						if (value) base = "" + value;
						return base;
					}
				};
			}
			break;
		case 'switch':
			recoverVal = ele.__config__.defaultValue;
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = {
					required: true,
					message: ele.__config__.label + '为必填项',
					type: 'boolean',
					trigger: 'change'
				};
			}
			break;
		case 'time':
		case 'date':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : '';
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = {
					required: true, message: ele.__config__.label + '为必填项', type: 'string', trigger: 'change',
					transform: (value) => {
						let base;
						if (value) base = "" + value;
						return base;
					}
				}
			}
			break;
		case 'cascader':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : [];
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'checkbox':
		case 'time-range':
		case 'date-range':
		case 'upload':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : [];
			if(ele.__config__.tagIcon === 'time-range') {
				recoverVal = [new Date(),new Date()]
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'form':
			recoverVal = [];
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'bimList':
			recoverVal = [];
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'gisList':
			recoverVal = [];
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'user':
			recoverVal = [];
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'treeSelect':
			recoverVal = '';
			ele.options = []
			const isMulti = ele.props.props.multiple;
			if(isMulti) {
				recoverVal = [];
				type = 'array';
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: type, trigger: 'change'};
			}
			break
		case 'signature':
		case 'position':
			recoverVal = [];
			type = 'array';
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: type, trigger: 'change'};
			}
			break
		default:
			itemData = {type: ele.__config__.tagIcon, config: ele};
			if(ele.__vModel__){
				itemData.field = ele.__vModel__;
				itemData.value = '';
			}
	}

	return itemData;
}

export default formatElement;
