<!--
 * @Description: 选择文件
 * @Author: luocheng
 * @Date: 2022-03-03 15:02:56
 * @LastEditors: luocheng
 * @LastEditTime: 2022-03-03 15:30:23
-->
<template>
  <div class="select-files">
    <section class="file-list part-box">
      <header class="header">
        <el-input v-model="fileName" placeholder="请输入文件名查询" clearable></el-input>
      </header>
      <div class="part-main">
        <el-tree
          :data="fileList"
          show-checkbox
          node-key="id"
          default-expand-all
          :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node }">
            <span>{{ node.label }}</span>
            <span>
              <el-button
                type="text"
                size="mini">
                Append
              </el-button>
            </span>
          </span>
        </el-tree>
      </div>
    </section>
    <!-- 选择结果 -->
    <section class="select-result part-box">
      <header class="header result-header">
        <p class="count">
          <span>已选</span>
          <span class="bold">{{ count }}</span>
          <span>项</span>
        </p>
        <a href="javascript:;">清空</a>
      </header>
      <div class="part-main">
        <ul v-if="selectList && selectList.length"></ul>
        <el-empty description="请选择文件" v-else></el-empty>
      </div>
    </section>
  </div>
</template>

<script>
import { Empty, Tree } from 'element-ui';

export default {
  name: 'SelectFiles',
  components: {
    'el-tree': Tree,
    'el-empty': Empty
  },
  data() {
    return {
      // 筛选
      fileName: '',
      // 列表
      fileList: [{
        id: 456,
        label: '这是外层文件'
      },{
        id: 1,
        label: '一级 1',
        children: [{
          id: 4,
          label: '二级 1-1',
          children: [{
            id: 9,
            label: '三级 1-1-1'
          }, {
            id: 10,
            label: '三级 1-1-2'
          }]
        }]
      }, {
        id: 2,
        label: '一级 2',
        children: [{
          id: 5,
          label: '二级 2-1'
        }, {
          id: 6,
          label: '二级 2-2'
        }]
      }, {
        id: 3,
        label: '一级 3',
        children: [{
          id: 7,
          label: '二级 3-1'
        }, {
          id: 8,
          label: '二级 3-2'
        }]
      },
      {
        id: 10,
        label: '一级 4',
        children: [{
          id: 11,
          label: '二级 1-1',
        },
          {
            id: 12,
            label: '二级 1-1',
          },
          {
            id: 14,
            label: '二级 1-1',
          },
          {
            id: 15,
            label: '二级 1-1',
          },
          {
            id: 16,
            label: '二级 1-1',
          },
          {
            id: 17,
            label: '二级 1-1',
          },
          {
            id: 18,
            label: '二级 1-1',
          },
          {
            id: 19,
            label: '二级 1-1',
          },
          {
            id: 120,
            label: '二级 1-1',
          },
          {
            id: 121,
            label: '二级 1-1',
          },
          {
            id: 122,
            label: '二级 1-1',
          }
        ]
      },],
      // 已选
      selectList: []
    }
  },
  computed: {
    count() {
      return this.selectList.length || 0
    }
  }
}
</script>

<style lang="less" scoped>
.select-files{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  .part-box{
    width: 48%;
    height: 100%;
    overflow: hidden;
    border: 1px solid #f5f5f5;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    header{
      height: 57px;
      box-sizing: border-box;
      border-bottom: 1px solid #f5f5f5;
      box-sizing: border-box;
      padding: 10px;
      background: rgb(239, 239, 239);
      :deep(.el-input--small .el-input__inner) {
        height: 36px;
        line-height: 36px;
        border-radius: 18px;
      }
      &.result-header{
        display: flex;
        width: 100%;
        .count{
          flex: 1;
          display: flex;
          span{
            font-size: 14px;
            line-height: 36px;
            color: #606266;
            &.bold{
              margin: 0 2px;
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
        a{
          line-height: 36px;
          color: @theme;
          font-size: 14px;
          box-sizing: border-box;
          padding: 0 10px;
          &:hover{
            font-weight: bold;
          }
        }
      }
    }
    .part-main{
      flex: 1;
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
</style>