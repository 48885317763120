 const clickOutside = {
     mounted(el, binding) {
        function clickHandler(e) {
            if (!el.contains(e.target)) {
                binding.instance.clickOutsideEvent(false);
            }
        }
        el.__vueClickOutside__ = clickHandler;
        document.addEventListener("click", clickHandler);
    },

    update() {},

    unmounted(el) {
        document.removeEventListener("click", el.__vueClickOutside__);

        delete el.__vueClickOutside__;
    }
};

export default clickOutside
