<template>
  <dv-decoration-6 class="decoration-container" :color="color"></dv-decoration-6>
</template>
<script>
import { decoration6 } from '@jiaminghi/data-view'
import Vue from 'vue'
import Minin from './mixin'
Vue.use(decoration6)
export default {
  name: 'Decoration6',
  mixins: [Minin]
}
</script>
<style lang="less" scoped>
  .decoration-container{
    width: 100%;
    height: 100%;
  }
</style>