<!--
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2022-10-28 12:01:05
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
  <div class="drawing-choose-cont">
    <div class="list relative">
      <el-page-header
        class="page-header"
        content="图纸"
        @back="back"
      />
      <list
        ref="listRef"
        class="list-cont scroller"
        :drawing-data="drawingData"
        :search="search"
        @clear-obk="clearObk"
        @show-model="showModel"
      />
      <el-button
        class="absolute"
        size="mini"
        style="top: 8px; right: 10px"
        type="primary"
        @click="commit()"
      >
        确定
      </el-button>
    </div>
    <div class="flex1">
      <el-tabs v-if="editableTabs.length != 0" v-model="editableTabsValue" class="w-full h-full" type="border-card" @tab-click="tabClick">
        <el-tab-pane
          v-for="item in editableTabs"
          :key="item.name"
          :label="item.name"
          :name="JSON.stringify(item.id)"
        >
          <Preview
            v-if="previewShow"
            ref="preview"
            class="preview-cont"
            :info="item"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import List from './modelList.vue'
import Preview from './preview.vue'
import { TabPane, Tabs, PageHeader } from "element-ui";
export default {
  name: 'Bind',
  components: { 
		List,
		Preview,
		'el-tab-pane': TabPane,
		'el-tabs': Tabs,
		'el-page-header':PageHeader,
	},
  props: {
    drawingData: {
      type: Array,
      default: () => []
    },
    search:{
			type: Array,
      default: () => [],
		}
  },
	data() {
    return {
      previewShow: true,
      selectModelInfo: [], // 包含构建信息得模型
      selectModelList: [], // 只包含模型model_id
      editableTabs: [],
      editableTabsValue: ''
    }
	},	
	methods: {
		showModel (data, bol) {
				if (bol) {
					data.forEach((item) => {
						let it = item
						if (this.editableTabs.length === 0 || this.editableTabs.filter((v) => v.id == item.id).length === 0) {
							if (this.editableTabs.length === 0) {
								this.editableTabsValue = JSON.stringify(data[0].id);
							}
							this.editableTabs.push(it)
						}
					})
				} else {
					this.editableTabs.forEach((item, i) => {
						if (data.filter((v) => v.id === item.id).length !== 0) {
							this.editableTabs.splice(i, 1)
						}
					})
				}
			},
			clearObk () {
				this.editableTabs = []
			},
			back () {
				this.$emit('back')
			},
			commit () {
				if (this.editableTabs.length != 0) {
					this.$emit('set-data', this.editableTabs)
				} else {
					this.$message.warning('请选择图纸')
				}
			},
			tabClick ()  {
				console.log(this.editableTabsValue, 11111)
			}
	},
	mounted(){
		if (this.drawingData && this.drawingData.length !== 0) {
			this.editableTabsValue = JSON.stringify(this.drawingData[0].id);
			if(Object.prototype.toString.call(this.drawingData)=="[object String]"){
				this.editableTabs = JSON.parse(this.drawingData)
			}else{
				this.editableTabs = JSON.parse(JSON.stringify(this.drawingData)) 
			}
			
		}
	}
}
</script>
<style lang="less">
.drawing-choose-cont {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    overflow: hidden;
    .list {
        width: 300px;
        height: 100%;
        z-index: 120;
        border-right: 1px solid #dcdfe6;
        .page-header {
            line-height: 45px;
            padding-left: 15px;
            border-bottom: 1px solid #dcdfe6;
        }
        .list-cont {
            height: calc(100% - 45px);
        }
    }
    .preview-cont {
        height: 100%;
        flex: 1;
    }
    .other-btn {
        position: absolute;
        top: 10px;
        left: 310px;
        height: 20px;
        z-index: 100;
        i {
            font-size: 18px;
        }
    }
		.el-tabs__item{
			height: 46px !important;
			line-height: 46px !important
		}
		.el-tabs__content{
			height: calc(100% - 76px);
		}
		.el-tab-pane{
			height: 100%;
		}
		.el-page-header__content {
        font-size: 14px !important;
    }
		.el-tabs--border-card{
			border: none !important;
			box-shadow: none !important;
		}
    .el-page-header__left {
        color: #409eff;
        animation: fade 1500ms infinite;
        -webkit-animation: fade 1500ms infinite;
    }
    @keyframes fade {
        from {
            opacity: 1;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }

    @-webkit-keyframes fade {
        from {
            opacity: 1;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1;
        }
    }
		.flex1{
			flex: 1;
		}
		.relative {
        position: relative;
    }
    .absolute {
        position: absolute;
    }
}

</style>
