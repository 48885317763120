
<template>
  <dv-border-box-5 class="border-box-container" :reverse="reverse" :color="color">
    <template v-if="textContent">
      <span :style="style">{{ textContent }}</span>
    </template>
  </dv-border-box-5>
</template>
<script>
import { borderBox5 } from '@jiaminghi/data-view'
import Vue from 'vue'
import Minin from './mixin'
Vue.use(borderBox5)
export default {
  name: 'Border5',
  mixins: [Minin]
}
</script>
<style lang="less">
  .border-box-container{
    width: 100%;
    height: 100%;
    .border-box-content{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>