/*
 * @Author: lisushuang
 * @Date: 2021-11-22 23:23:24
 * @LastEditors: lisushuang
 * @LastEditTime: 2021-11-23 00:00:49
 * @Description: 前端可选的本地取值函数
 * @FilePath: /dataview-viewer-test/src/custom-component/form/formParser/scripts/formValueFunctions.js
 */

export default {
  computed:{
    functionValues(){
      return {
        companyIdForced: this._getCompanyIdForced(),
        archiId: this._getArchiId(),
        archiSearchValue: this._getArchiSearchValue()
      }
    }
  },
  methods: {
    /**
     * @description: 强制获取公司id，无论在项目还是在公司，都获取公司id
     * @param {*}
     * @return {*}
     */
    _getCompanyIdForced () {
      let archi = this.$GetTargetArchi()

      if (archi.archiType == 'company') {
        return archi.archi_id
      } else {
        return archi.company_id
      }
    },

    /**
     * @description: 获取组织架构id，无论在公司还是在项目，都只要id
     * @param {*}
     * @return {*}
     */
    _getArchiId () {
      let archi = this.$GetTargetArchi()
      return archi.archi_id
    },

    /**
     * @description: 获取架构搜索值，主要用于搜索
     * @param {*}
     * @return {*}
     */
    _getArchiSearchValue (){
      let archi = this.$GetTargetArchi()
      return archi.data_id
    }
  }

}
