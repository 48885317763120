/*
 * @Date: 2022-12-09 14:07:31
 * @LastEditors: AsaHi
 * @LastEditTime: 2023-01-05 16:07:24
 * @FilePath: \dataview-next\src\custom-component\viewerBIM\Viewer\tools\graffitiTool.js
 */
import Utils from '../../Utils.js';
export default class GraffitiTool{
    constructor( viewer ){
        this.viewer = viewer;
        this.MarkupTool = null;
        this.active = false;
        this.isEditMode = false;

        this.initEventListener();
    }

    initEventListener(){
        const viewer = this.viewer;

        viewer.on( 'ViewerInited', ()=>{
            const global = this.viewer.getGlobal();

            this.MarkupTool = new global.Autodesk.Viewing.Extensions.Markups.Core.MarkupsCore(this.viewer.getBIMViewer(),{
                markupDisableHotkeys:false
            });

            this.MarkupTool.load();
        });
    }

    
    open(){
        this.MarkupTool.show();
        this.MarkupTool.enterEditMode();
        this.editMode();
        this.active = true;
    }

    close(){
        this.MarkupTool.hide();
        if(this.MarkupTool.duringEditMode){
            this.MarkupTool.clear();
        }
        this.MarkupTool.leaveEditMode();
        this.active = false;
    }

    clear(){
        this.MarkupTool.clear();
    }

    switch () {
        if(this.active){
            this.close();
        }else{
            this.open();
        }
    }

    switchMode(){
        if(this.isEditMode){
            this.viewMode();
        }else{
            this.editMode();
        }
    }

    editMode(){
        this.MarkupTool.allowNavigation(false);
        this.isEditMode = true;
    }

    viewMode(){
        this.MarkupTool.allowNavigation(true);
        this.isEditMode = false;
    }

    getGraffiti(){
        return this.MarkupTool.generateData();
    }

    loadGraffiti(data,id = Utils.getGuid()){
        if(this.active){
            this.MarkupTool.clear();
            this.MarkupTool.leaveEditMode();
        }

        this.MarkupTool.loadMarkups(data,id);

        this.MarkupTool.enterEditMode(id);

        return id;
    }

    changeMode(type){
        const MarkupTool = this.MarkupTool;
        const global = this.viewer.getGlobal();
        const MarkCore = global.Autodesk.Viewing.Extensions.Markups.Core;
        let coreType = new MarkCore.EditModeArrow(MarkupTool);
        switch (type){
            case 'arrow' :
                coreType = new MarkCore.EditModeArrow(MarkupTool);
                break;
            case 'freehand':
                coreType = new MarkCore.EditModeFreehand(MarkupTool);
                break;
            case 'circle':
                coreType = new MarkCore.EditModeCircle(MarkupTool);
                break;
            case 'rectangle':
                coreType = new MarkCore.EditModeRectangle(MarkupTool);
                break;
            case 'cloud':
                coreType = new MarkCore.EditModeCloud(MarkupTool);
                break;
            case 'text':
                coreType = new MarkCore.EditModeText(MarkupTool);
                break;
        }

        this.MarkupTool.changeEditMode(coreType);
    }

    getSelection(){
        return this.MarkupTool.getSelection();
    }

    copy(){
        this.MarkupTool.copy();
    }

    paste(){
        this.MarkupTool.paste();
    }

    delete(selectionMark){
        if(!selectionMark){
            selectionMark = this.getSelection();
        }
        if(selectionMark){
            this.MarkupTool.deleteMarkup(selectionMark);
        }
    }

    undo(){
        this.MarkupTool.undo();
    }

    redo(){
        this.MarkupTool.redo();
    }

    setColor(color){
        this.MarkupTool.setStyle({
            'fill-color' : color,
            'stroke-color' : color,
        });
    }

    setWidth(width){
        this.MarkupTool.setStyle({
            'stroke-width' : width
        });
    }

    setFontSize(size){
        this.MarkupTool.setStyle({
            'font-size' : size
        })
    }

    setStyle(style){
        this.setColor(style.color);
        this.setWidth(style.width);
        this.setFontSize(style.fontSize);
    }
}