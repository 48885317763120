export default {
  props: {
    // 组件对象
    element: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    statusConfig() {
      return this.element.statusConfig || {}
    },
    // 主颜色与副颜色
    color() {
      if (this.statusConfig.mainColor && this.statusConfig.secondaryColor) {
        return [this.statusConfig.mainColor, this.statusConfig.secondaryColor]
      } else {
        return []
      }
    },
    // 动画时长
    dur() {
      if (this.statusConfig.dur && !isNaN(this.statusConfig.dur)) {
        return Number(this.statusConfig.dur)
      } else {
        return 6
      }
    },
    // 反转
    reverse() {
      if (this.statusConfig.reverse) {
        return this.statusConfig.reverse
      } else {
        return false
      }
    },
    // 文本内容
    textContent() {
      if (this.statusConfig.textContent) {
        return this.statusConfig.textContent
      } else {
        return ''
      }
    },
    // 雷达扫描颜色主
    scanDur() {
      if (this.statusConfig.scanDur) {
        return Number(this.statusConfig.scanDur)
      } else {
        return 6
      }
    },
    // 雷达扫描颜色副
    haloDur() {
      if (this.statusConfig.haloDur) {
        return Number(this.statusConfig.haloDur)
      } else {
        return 6
      }
    },
    // 标题
    title() {
      if (this.statusConfig.title) {
        return this.statusConfig.title
      } else {
        return ''
      }
    },
    // 标题盒子大小
    titleWidth() {
      if (this.statusConfig.titleWidth) {
        return Number(this.statusConfig.titleWidth)
      } else {
        return 250
      }
    },
    // 盒子内部样式 -*字体颜色 -*字体大小
    style() {
      const data = {}
      if (this.statusConfig.textColor) {
        data.color = this.statusConfig.textColor
      }
      if (this.statusConfig.textSize) {
        data.fontSize = this.statusConfig.textSize
      }
      if (this.statusConfig.textWeight) {
        data.fontWeight = this.statusConfig.textWeight
      }
      return data
    }
  }
}
