<!--
 * @Description: 随机数
 * @Author: luocheng
 * @Date: 2022-06-16 10:49:40
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-16 16:06:42
-->
<template>
  <div class="common-random-text fn-clear">
    <p class="value">{{ randomValue }}</p>
    <span class="unit" :style="unitStyle" v-if="statusConfig.showUnit">
      {{ statusConfig.unit }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CommonRandomText',
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      randomValue: 0,
      interval: null
    }
  },
  computed: {
    statusConfig() {
      return this.element?.statusConfig || {}
    },
    baseStyle() {
      return this.element?.style || {};
    },
    unitStyle() {
      const lineHeight = `${(this.baseStyle.fontSize - this.statusConfig.unitSize) / 2 + this.baseStyle.lineHeight}`
      return {
        lineHeight: `${lineHeight}px`,
        fontSize: `${this.statusConfig.unitSize}px`
      };
    },
  },
  watch: {
    statusConfig: {
      handler() {
        if (this.statusConfig.useRandom) {
          // 随机
          this.doInterval();
        }
      },
      deep: true
    }
  },
  created() {
    this.randomValue = this.statusConfig?.defaultVal;
    if (this.statusConfig.useRandom) {
      // 随机
      this.doInterval();
    }
  },
  methods: {
    /**
     * @desc: 随机生成值
     */
    doInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
      const { randomTime = 1000 } = this.statusConfig;
      this.interval = setInterval(() => {
        this.randomValue = this.generateRandom();
      }, isNaN(randomTime) ? 1000 : randomTime);
    },
    /**
     * @desc: 生成随机数
     */
    generateRandom() {
      const { min = 0, max = 1000, floatLength = 0 } = this.statusConfig;
      const val = Math.random() * max;
      if (val < min ) {
        return this.generateRandom();
      }
      return val.toFixed(isNaN(floatLength) ? 0 : floatLength);
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
    this.interval = null;
  }
}
</script>

<style lang="less" scoped>
.common-random-text{
  height: 100%;
  width: 100%;
  p,span{
    float: left;
  }
  .unit{
    margin-left: 5px;
  }
}
</style>