import Viewer from "../../Viewer";
import eventBus from '@/plugins/eventBus';

export default class Opacity {

    get id(){
        return "Opacity";
    }

    get data(){
        return {
        }
    }

    get watch (){
        return {

        }
    }

    get methods (){
        return {
            //行为注册
            opacity_initBehaviors(){
                // this.registerBehaviors();
            },
            setOpacity( selection , opacity ){
                for(let { model_id , dbids} of selection){
                    this.viewer.action('setOpacity' ,dbids , opacity, model_id);
                }
            },
        }
    }

    get func(){
        return {
            initViewerEventListener(){
                this.viewer.on(Viewer.Events.ViewerInited , ()=>{
                    setTimeout(()=>{
                        for(let id in this.element.modelBIMOpacity){
                            const { dbids , opacity } = this.element.modelBIMOpacity[id];

                            this.setOpacity(dbids , opacity);
                        }
                    } , 5000);
                });
            },
            mounted (){
                this.opacity_initBehaviors();

                if( !this.element?.modelBIMOpacity){
                    this.element.modelBIMOpacity = {}
                }

                eventBus.$on("ViewerBIM.Opacity.SyncOpacityData" , ( eid , data )=>{
                    if( eid !== this.element.id)return;

                    if ( this.element.modelBIMOpacity[data.id]){
                        const old = this.element.modelBIMOpacity[data.id];

                        let resetColorFlag = false;
                        if( JSON.stringify(old.dbids) !== JSON.stringify(data.dbids)) resetColorFlag = true;
                        if( old.opacity !== data.opacity) resetColorFlag = true;

                        this.element.modelBIMOpacity[data.id] = data;

                        if(resetColorFlag){
                            this.setOpacity(data.dbids , data.opacity);
                        }
                    }else{
                        this.element.modelBIMOpacity[data.id] = data;
                        this.setOpacity(data.dbids , data.opacity);
                    }
                });

                eventBus.$on("ViewerBIM.Opacity.ClearOpacityById" , ( eid , data )=>{
                    if( eid !== this.element.id)return;

                    this.setOpacity(data.dbids , 100);
                });

                eventBus.$on("ViewerBIM.Opacity.GetOpacityMap",(eid , cb)=>{
                    if( eid !== this.element.id)return;

                    cb && cb(Object.assign({} , this.element.modelBIMOpacity));
                });
            }
        }
    }
}

