<template>
  <dv-decoration-7 class="decoration-container" :color="color">
    <template v-if="textContent">
      <span :style="style">{{ textContent }}</span>
    </template>
  </dv-decoration-7>
</template>
<script>
import { decoration7 } from '@jiaminghi/data-view'
import Vue from 'vue'
import Minin from './mixin'
Vue.use(decoration7)
export default {
  name: 'Decoration7',
  mixins: [Minin]
}
</script>
<style lang="less" scoped>
  .decoration-container{
    width: 100%;
    height: 100%;
  }
</style>