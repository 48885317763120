export default {
  props: {
    // 绑定的值
		element: {
			type: Object,
			default: () => {},
			required: true
		},
  },
  computed: {
    statusConfig() {
      return this.element.statusConfig || {}
    },
    color() {
      if (this.statusConfig.mainColor && this.statusConfig.secondaryColor) {
        return [this.statusConfig.mainColor, this.statusConfig.secondaryColor]
      } else {
        return []
      }
    },
    direction() {
      return this.statusConfig.direction || ''
    },
    fontSize() {
      return this.statusConfig.fontSize || '16px'
    },
    textAlign () {
      return this.statusConfig.textAlign || 'center'
    },
    lineHeight () {
      return this.statusConfig.lineHeight || '32px'
    },
    // 显示效果
    showEffect () {
      return this.statusConfig.showEffect || ''
    },
    // 字体加粗
    bold () {
      return this.statusConfig.bold ? 'bold' : ''
    },
    // 斜体
    italic() {
      return this.statusConfig.italic ? 'italic' : ''
    },
    // 字体
    fontFamily() {
      return this.statusConfig.fontFamily || ''
    },
    // 补零
    zeroFilling() {
      return this.statusConfig.zeroFilling
    },
    // 时间格式化
    timeFormat() {
      return this.statusConfig.timeFormat
    },
    // 自定义时间格式化
    timeFormatString() {
      return this.statusConfig.timeFormatString
    },
    // 格式化中文
    formatChinese() {
      return this.statusConfig.formatChinese
    },
    // 星期显示类型
    dayShowType() {
      return this.statusConfig.dayShowType
    },
    // 自定义星期格式化
    dayFormatString() {
      return this.statusConfig.dayFormatString
    },
    // 翻盘器单位内容
    content() {
      return this.statusConfig.content
    },
    // 翻盘器分隔符
    separator() {
      return this.statusConfig.separator
    },
    // 翻盘器动画时长
    animationFrame() {
      return this.statusConfig.animationFrame
    }
  }
}