<!--
 * @Description: 列表模板
 * @Author: luocheng
 * @Date: 2021-12-03 13:40:51
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-13 11:13:42
-->
<template>
  <div class="list-template">列表模板
    <ul>
      <li v-for="(value, key) in templateConfig" :key="key">
      {{ value }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ListTemplate',
  props: {
    element: {
      type: Object,
      required: true,
      default: () => { }
    },
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		}
  },
  computed: {
    templateConfig() {
      return this.element && this.element.templateConfig;
    }
  },
  // watch: {
  //   templateConfig: {
  //     handler() {
  //       console.log(JSON.stringify(this.templateConfig), '9999')
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // }
}
</script>

<style lang="less" scoped>
.list-template {
}
</style>