// 首页待办相关的store

export default {
  state: {
    activeItem: {}, //存放活动的小项
    activeItemIndex: 0,
    menuListIndex: [
      {
        icon: 'iconjijia_dangqian',
        title: '全部',
        type: '0',
        num: 0,
        data: [],
      },
      {
        icon: 'icondaichuli',
        title: '待处理',
        type: '1',
        num: 0,
        data: [],
      },
      {
        icon: 'iconfaqi',
        title: '我发起的',
        type: '2',
        num: 0,
        data: [],
      },
      {
        icon: 'iconchaosong',
        title: '抄送给我',
        type: '3',
        num: 0,
        data: [],
      },
      {
        icon: 'iconyiwancheng',
        title: '已处理',
        type: '-1',
        data: [],
        num: 0,
      },
    ], // 存放左侧列表
    searchParams: {}, // 存放筛选数据
    sortFiled: '', //存放排序字段
    openDrawer: false, //控制打开弹窗
    drewerData: {}, //打开弹窗的参数
    searchLoding: false,
    activeTaskData: {}, //活动任务信息
    finishSearchParams: {
      size: 15,
      page: 1,
    }, //已完成检索参数
    todoList: [], // 待办列表
    refesh: 0,
  },
  mutations: {
    setActiveItem(state, data) {
      state.activeItem = data
    },
    setActiveItemIndex(state, data) {
      state.activeItemIndex = data
    },
    setIndexMenuList(state, data) {
      state.menuListIndex = data
    },
    setSearchParams(state, data) {
      state.searchParams = data
    },
    setSortFiled(state, data) {
      state.sortFiled = data
    },
    setOpenDrawer(state, data) {
      state.openDrawer = data
    },
    setDrewerData(state, data) {
      state.drewerData = data
    },
    setSearchLoading(state, data) {
      state.searchLoding = data
    },
    setActivetaskData(state, data) {
      state.activeTaskData = data
    },
    setFinishSearchParams(state, data) {
      state.finishSearchParams = data
    },
    setTodoList(state, data) {
      state.todoList = data
    },
    setRefesh(state) {
      state.refesh += 1
    },
  },
  getters: {
    getActiveItem(state) {
      return state.activeItem
    },
    // 返回表单的tag
    getActiveItemTag(state){
      let tags = [
        'all',
        'check',
        'mine',
        'copy',
        'finish'
      ]
      if(0 <= state.activeItemIndex && state.activeItemIndex < tags.length){
        return tags[state.activeItemIndex]
      }else{
        return ''
      }
    },
    getActiveIndex(state) {
      return state.activeItemIndex
    },
    getIndexMenuList(state) {
      return state.menuListIndex
    },
    getSearchParams(state) {
      return state.searchParams
    },
    getSortFiled(state) {
      return state.sortFiled
    },
    getOpenDrawer(state) {
      return state.openDrawer
    },
    getDrewerData(state) {
      return state.drewerData
    },
    getSearchLoding(state) {
      return state.searchLoding
    },
    getActivetaskData(state) {
      return state.activeTaskData
    },
    getFinishSearchParams(state) {
      return state.finishSearchParams
    },
    getTodoList(state) {
      return state.todoList
    },
    getRefesh(state) {
      return state.refesh
    },
  },
}
