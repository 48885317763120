<!--
    @name: MsgConfig
    @description：MsgConfig
    @author: ZengWei
    @date: 2022-05-19 10:18
-->
<template>
  <div class="msg-config">
    <el-button
      v-if="saveBtn && message.msg_new === 2"
      class="save-msg" icon="el-icon-s-claim" size="mini"
      @click="submit">
      保存模板
    </el-button>
    <el-form ref="msgForm" :model="message" :rules="rules" label-position="top" label-width="80px">
      <el-form-item label="消息类型" prop="msg_new">
        <el-radio-group v-model="message.msg_new">
          <el-radio :label="1">选择模板</el-radio>
          <el-radio :label="2">新增模板</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="message.msg_new === 1">
        <el-form-item label="消息模板" prop="msg_select">
          <el-select
            filterable
            v-model="message.msg_select"
            @change="onChange"
            class="select-item"
            placeholder="请选择模板消息">
          </el-select>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="模板名称" prop="msg_name">
          <el-input v-model="message.msg_name"></el-input>
        </el-form-item>
        <el-form-item label="模板类型" prop="msg_type">
          <el-select
            v-model="message.msg_type"
            class="select-item" placeholder="请选择模板消息类型">
            <el-option label="短信模板" :value="1"></el-option>
            <el-option label="微信模板" :value="2"></el-option>
            <el-option label="邮件模板" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="消息标题" prop="msg_title">
          <el-input v-model="message.msg_title"></el-input>
        </el-form-item>
        <el-form-item label="模板内容" prop="msg_content">
          <el-input v-model="message.msg_content"></el-input>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
import {RadioGroup,Radio} from "element-ui";

export default {
  name: "MsgConfig",
  components: {
    'el-radio-group': RadioGroup,
    'el-radio': Radio,
  },
  props: {
    msgSelect: {
      type: [Number,String],
    },
    saveBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      message:{
        msg_new: 1,
        msg_select:'',
        msg_name:'',
        msg_type:'',
        msg_title:'',
        msg_content:'',
      },
      rules: {
        msg_new: [
          { required: true, message: '请选择消息类型', trigger: 'change' },
        ],
        msg_select: [
          { required: true, message: '请选择消息模板', trigger: 'change' },
        ],
        msg_name: [
          { required: true, message: '请输入模板名称', trigger: 'blur' },
        ],
        msg_type: [
          { required: true, message: '请选择模板类型', trigger: 'change' },
        ],
        msg_title: [
          { required: true, message: '请输入模板标题', trigger: 'blur' },
        ],
        msg_content: [
          { required: true, message: '请输入模板内容', trigger: 'blur' },
        ],
      },
    }
  },
  mounted() {
    this.message.msg_select = this.msgSelect
  },
  methods: {
    onChange(){
      this.$emit('on-change', this.message.msg_select)
    },
    submit(){
      this.$refs.msgForm.validate((valid)=>{
        if(valid){
          this.message.info('功能暂未启用')
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.select-item{
  width: 100%;
}
.msg-config{
  position: relative;
}
.save-msg {
  position: absolute;
  top: 3px;
  right: 0;
}
</style>
