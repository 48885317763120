<!--
 * @Author: zqy
 * @Date: 2022-09-05 11:11:27
 * @LastEditTime: 2022-10-18 17:05:18
 * @Description: 
 * @LastEditors: zx
-->
<template>
  <div class="app-config">
    <header>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="APP配置" name="appConfig"></el-tab-pane>
      </el-tabs>
    </header>
    <article v-if="activeName==='appConfig'" v-loading="loadingFlag">
      <section class="app-wrap">
        <div class="android common-css">
          <svg class="iconfont-svg" aria-hidden="true">
            <use xlink:href="#iconanzhuo" />
          </svg>
          <div class="padding-b-t">
            <a v-if="appDetail && appDetail.android" :href="appDetail.android" target="_blank">下载</a>
            <span v-if="appDetail && !appDetail.android" style="color:red">尚未设置</span>
          </div>
          <el-upload
            class="upload-css"
            ref="androidUpload"
            drag
            :headers="{bimcctoken:token}"
            :action="baseUrl+'api/app/importAndroid'"
            accept=".apk"
            :on-success="uploadSuccess"
            multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或 <em>点击上传</em></div>
          </el-upload>
        </div>
        <div class="ios common-css">
          <svg class="iconfont-svg" aria-hidden="true">
            <use xlink:href="#iconiOS" />
          </svg>
          <div class="padding-b-t" v-if="appDetail">
            <span>兑换码总量 :</span>
            {{appDetail.ios_all}}
            <span>| 剩余兑换码 :</span>
            {{appDetail.ios_can}}
          </div>
          <el-upload
            class="upload-css"
            ref="iosUpload"
            drag
            :headers="{bimcctoken:token}"
            :action="baseUrl+'api/app/importIosCode'"
            accept=".xls, .xlsx"
            :on-success="uploadSuccess"
            multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或 <em>点击上传</em></div>
          </el-upload>
        </div>
      </section>
    </article>
  </div>
</template>
<script>
import { Tabs, TabPane } from 'element-ui';
import { formRequest } from "@/apis/data/form";
import { getToken } from '@/utils/tools';
import { Upload } from 'element-ui';

export default {
  components:{
    'el-tabs':Tabs,
    'el-tab-pane':TabPane,
    'el-upload': Upload
  },
  data(){
    return{
      activeName:'appConfig',
      appDetail: null,
      baseUrl: process.env.VUE_APP_BASE_URL || 'https://saas-api.bimcc.net/',
      token: getToken(),
      loadingFlag: false
    }
  },
  methods:{
    uploadSuccess(res){
      this.$refs.androidUpload.clearFiles();
      this.$refs.iosUpload.clearFiles();
      if(+res.code === 200){
        this.getStatus();
      }else{
        this.$message({
          showClose: true,
          message: '上传失败！',
          type: 'error'
        });
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getStatus(){
      this.loadingFlag = true;
      formRequest('post','/api/app/getStatus',{}).then(res => {
        this.loadingFlag = false;
        if (res.data.code === 200) {
          const data = res.data.data;
          this.appDetail = data;
        } else {
          this.$message({
            showClose: true,
            message: '获取失败！',
            type: 'error'
          });
        }
      }).catch(err=>{
        console.log(err,'err');
        this.loadingFlag = false;
        this.$message({
          showClose: true,
          message: '获取失败！',
          type: 'error'
        });
      })
    },
  },
  mounted(){
    this.getStatus();
  }
}
</script>
<style lang="less">
.app-config{
  .el-upload-dragger{
    width: 260px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .el-icon-upload{
    margin: unset !important;;
  }
}
</style>
<style lang="less" scoped>
.app-config{
  height: 100%;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  .app-wrap{
    display: flex;
    padding-top: 20px;
    .common-css{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }
    .android{

    }
    .ios{

    }
  }
  .padding-b-t{
    padding: 20px 0;
  }
  // .upload-css{
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   .el-upload__text{
  //     padding-top: 20px;
  //   }
  // }
}
</style>