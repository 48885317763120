var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"chart-flop",attrs:{"element-loading-background":_vm.loadingBackColor}},[(_vm.commonConfig && _vm.commonConfig.showTitle)?_c('h3',{staticClass:"title",style:({
			..._vm.commonConfig.titleStyle
		})},[_vm._v(" "+_vm._s(_vm.commonConfig.title || '')+" ")]):_vm._e(),(_vm.staticData && _vm.staticData.length)?_c('article',{staticClass:"flop-main"},_vm._l((_vm.staticData),function(item,index){return _c('section',{key:index,staticClass:"flop-item",style:({
				width: _vm.commonConfig && _vm.commonConfig.width || '100%'
			})},[_c('section',{staticClass:"value-row"},[(item.isMoney)?_c('span',{staticClass:"money"},[_vm._v(" "+_vm._s(item.moneyType)+" ")]):_vm._e(),_c('span',{staticClass:"value-text",style:({
						...item.valueConfig.style
					})},[_vm._v(_vm._s(item.value || '--'))]),(item.unitConfig.showUnit)?_c('span',{staticClass:"unit",style:({
						...item.unitConfig.style
					})},[_vm._v(" "+_vm._s(item.unitConfig.unit)+" ")]):_vm._e()]),(item.labelConfig.showLabel)?_c('h4',{staticClass:"label-box",style:({
				...item.labelConfig.style
			})},[_vm._v(_vm._s(item.label || 0))]):_vm._e()])}),0):_c('el-empty',{attrs:{"description":"暂无数据"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }