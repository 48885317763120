<!--
 * @Description: 输入框
 * @Author: luocheng
 * @Date: 2021-09-24 10:27:21
 * @LastEditors: luocheng
 * @LastEditTime: 2021-09-24 10:28:47
-->
<template>
  <el-input v-model="name"></el-input>
</template>

<script>
export default {
  name: 'CommonInput',
  data() {
    return {
      name: ''
    }
  }
}
</script>

<style>

</style>