const valids = {
  name: [
    { required: true, message: '请填写', trigger: 'blur' }
  ],
  phone: [
    { validator: (rule, value, callback) => {
        if(!value) {
          callback(new Error('请填写'))
        }
        const reg = /^0?(13[0-9]|15[012356789]|17[013678]|18[0-9]|14[57])[0-9]{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('手机号格式错误'))
        }
      }, trigger: 'blur'
    }
  ],
  email: [
    { validator: (rule, value, callback) => {
        if(!value) {
          callback(new Error('请填写'))
        }
        const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('邮箱格式错误'))
        }
      }, trigger: 'blur'
    }
  ],
  stuNum: [
    { required: true, message: '请填写', trigger: 'blur' }
  ],
  jobNum: [
    { required: true, message: '请填写', trigger: 'blur' }
  ],
  class: [
    { required: true, message: '请填写', trigger: 'blur' }
  ],
  dept: [
    { required: true, message: '请填写', trigger: 'blur' }
  ],
  gender: [
    { required: true, message: '请填写', trigger: 'blur' }
  ],
  idCarNum: [
    { validator: (rule, value, callback) => {
        if(!value) {
          callback(new Error('请填写'))
        }
        const reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('身份证格式错误'))
        }
      }, trigger: 'blur'
    }
  ],
  IdCarPhoto: [
    { required: true, message: '请填写', type: 'array', trigger: 'blur' }
  ]
}
export default function(item) {
  if (item.required) {
    return valids[item.key]
  } else {
    return []
  }
}