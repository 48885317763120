<!--
 * @Description: 总进度曲线筛选
 * @Author: luocheng
 * @Date: 2022-06-01 15:36:20
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-28 15:51:13
-->
<template>
  <el-form :model="filterForm" class="overall-filter" :inline="true">
    <el-form-item>
      <el-select
        v-model="filterForm.typeId"
        placeholder="请选择计划类别"
        style="width: 160px; margin-right: 10px"
        @change="getPlanList"
      >
        <el-option
          v-for="item in typeData"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-select
        v-model="filterForm.listId"
        placeholder="请选择计划"
        style="width: 160px"
        @change="getPlan"
      >
        <el-option
          v-for="item in listData"
          :key="item.id"
          :label="item.name+'/'+item.plan_edition"
          :value="item.id"
        />
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import { dataInterface } from '@/apis/data';

export default {
  name: 'OverallFilter',
  data() {
    return {
      typeData: [],
      listData: [],
      filterForm: {
        typeId: '',
        listId: ''
      },
      colorArr: ['#1A73E8', '#FF8C00', '#8B8682', '#ff3333', '#FFD700', '#836FFF'] // 颜色数组
    }
  },
  created() {
    this.getPlanTypes();
  },
  methods: {
    /**
     * @desc: 获取计划类别
     */
    getPlanTypes() {
      dataInterface({
        archi_type: this.$GetTargetArchi()?.id, // 测试，正式由参数传入
        object_uuid: 'object61a89029d0dc3',
        view_uuid: 'view61a892c2b6e1a',
        __method_name__: 'dataList',
        transcode: 0
      }).then(res => {
        this.typeData = res?.data?.data?.data || [];
        this.filterForm.typeId = this.typeData?.[0].id;
        this.getPlanList();
      }).catch(err => {
        console.log(err);
      });
    },
    /**
     * @desc: 获取计划列表
     */
    getPlanList() {
      this.listData = [];
      if (!this.filterForm.typeId) return;
      dataInterface({
        size: 1000,
        type_id: this.filterForm.typeId,
        __method_name__: 'dataList',
        view_uuid: 'view61d552a955ca0',
        object_uuid: 'object61a973b39cf96',
        transcode: 0
      }).then(res => {
        this.listData = res.data?.data || [];
        this.filterForm.listId = this.listData?.[0].id;
        this.getOption();
      }).catch(err => {
        console.log(err);
      });
    },
    /**
     * @desc: 获取配置
     */
    async getOption() {
      let result = {};
      const dataOne = await this.getData({
        schedule_id: this.filterForm.listId,
        type: 1
      });
      dataOne.forEach((ele, index) => {
        result = Object.assign(result, { [`${ele.schedule_name}${index ? index : ''}`]: ele.schedule_list })
      });
      const dataTwo = await this.getData({
        schedule_id: this.filterForm.listId,
        type: 2
      });
      if (dataTwo.length) {
        result = Object.assign(result, { 实际进度: dataTwo })
      }
      this.buildData(result);
    },
    /**
     * @desc: 获取统计数据
     */
    getData(param) {
      return new Promise((resolve, reject) => {
        dataInterface({
          ...param
        }, 'api/fourSchedule/countMoney', 'GET').then(res => {
          resolve(res?.data?.data || [])
        }).catch(err => {
          console.log(err);
          reject([]);
        })
      });
    },
    /**
     * @desc: 获取计划
     */
    getPlan() {},
    /**
     * @desc: 构建数据
     */
    buildData(data) {
      // 销毁echart
      const rangeX = []
      const seriesList = []
      let n = 0
      for (var i in data) {
        if (i !== '实际进度') {
          n++
        }
        const item = data[i]
        const innerData = []
        if (item.length !== 0) {
          item.forEach((item) => {
            innerData.push(item.rate * 100)
            rangeX.push(item.date)
            // 日期升序排序
            rangeX.sort(function (a, b) {
              return Date.parse(a) - Date.parse(b)
            })
          })
        }
        const obj = {
          name: i,
          type: 'line',
          data: innerData,
          smooth: true,
          emphasis: {
            focus: 'series'
          },
          itemStyle: {
            normal: {
              color: i === '实际进度' ? 'green' : this.colorArr[n - 1],
              lineStyle: {
                width: i === '实际进度' ? 3 : 1.5,
                type: i === '实际进度' ? 'solid' : 'dotted', // 'dotted'虚线 'solid'实线
                color: i === '实际进度' ? 'green' : this.colorArr[n - 1]
              }
            }
          }
        }
        seriesList.push(obj)
      }
      // 绘制图表
      this.option = {
        animationDuration: 3000,
        title: { align: 'center' },
        tooltip: {
          trigger: 'axis',
          formatter (params) {
            let str = params[0].axisValueLabel + '<br/>'
            for (let i = 0; i < params.length; i++) {
              str += '<div style="margin: 0px 0 0;line-height:1;">' +
              '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:' + params[i].color + '"></span>' +
              '<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">' + params[i].seriesName + '</span>' +
              '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' + (params[i].value).toFixed(2) + '%</span>' +
              '<div style="clear:both"></div>' +
              '</div>'
            }
            return str
          }
        },
        legend: {
          orient: 'vertical', // 垂直显示
          y: 'center', // 延Y轴居中
          x: 'right' // 居右显示
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: Array.from(new Set(rangeX))
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}' + '%'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: seriesList
      };
    }
  }
}
</script>

<style lang="less" scoped>
.overall-filter{
  width: auto;
}
</style>