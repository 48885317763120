<!--
 * @Description: 外部页面容器
 * @Author: luocheng
 * @Date: 2022-04-28 10:18:22
 * @LastEditors: luocheng
 * @LastEditTime: 2022-04-28 10:18:23
-->
<template>
  <div class="out-page">
    外部页面容器
  </div>
</template>

<script>
export default {
  name: 'OutPage'
}
</script>

<style lang="less" scoped>
.out-page{
  height: 100%;
  width: 100%;
}
</style>
