<!--
 * @Description: 基础模型组件
 * @Author: luocheng
 * @Date: 2021-10-14 09:39:11
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-29 11:58:04
-->
<template>
	<div style="position: relative">
		<div v-show="show" class="tower-sign" :style="{borderColor:element.towerConfig.signCol}" >
			<div class="circle-cont" :style="getStyle">
				<div class="line-fixed" :style="{background:element.towerConfig.signCol}"></div>	
				<div class="line-move" :style="{background:element.towerConfig.signCol,transform: 'rotate('+(180 - randomValue)+'deg)',marginTop:randomValue>270?'2px':'0px'}"></div>	
				<span class="deg-num" :style="{color:element.towerConfig.signCol}">{{randomValue}}°</span>
				<span class="dot" :style="{background:element.towerConfig.signCol}"></span>
			</div>
			<i @click.stop="showMonitor" :style="{color:element.towerConfig.signCol}" class="iconfont icontadiao" ></i>
			<span class="trangle" :style="{borderTopColor:element.towerConfig.signCol}"></span>
		</div>
		<div class="mask" v-if="$route.path === '/modify-page' && showToggle"></div>
		<!-- 遮罩 -->
		<a href="javascript:;" class="mask-btn" v-if="$route.path === '/modify-page'" @click="onToggle">{{ showToggle ? '隐藏遮罩' : '显示遮罩' }}</a>
	</div>
</template>

<script>
import eventBus from '@/plugins/eventBus';
// import { dataInterface } from '@/apis/data';
import { mapState } from 'vuex';
import { initParams } from '@/utils/tools';
export default {
  name: 'CommonTowerSign',
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
  },
	components: {
	},
  data() {
    return {
			showDetail:false,
			showToggle:true,
			show: true,
			randomValue: 320,
      interval: null
    }
  },
  computed: {
    ...mapState(['componentData']),
    // 模型配置
    monitorConfig() {
      return this.element && this.element.towerConfig;
    },
		getStyle() {
			let style  = {
				width:2* Number(this.element.towerConfig.towerLong)+'px',
				height:1* Number(this.element.towerConfig.towerLong)+'px',
				marginLeft:14-Number(this.element.towerConfig.towerLong) +'px',
				borderColor:this.element.towerConfig.signCol,
				background:this.element.towerConfig.armBg,
				top:-30 - Number(this.element.towerConfig.towerLong)+'px'
			}
			return style
		}
  },
  mounted() {
		this.doInterval();
		this.$nextTick(() => {
      eventBus.$on('doComponentBehavior', config => {
        const { component, list = [] } = config;
        if (component !== this.element.id) return false;
        list.forEach(ele => {
          const { behaviors, params } = ele;
					const { param = {}, canPost } = initParams(params, this.isGroup, this.componentList || this.componentData, this.groupComponents);
          if (canPost) {
            // 调用行为方法
            behaviors.forEach(funName => {
              try {
                eval(this[funName])(param)
              } catch (err) {
                console.log(err);
              }
            });
          }
        });
      })
    });
  },
  updated() {
  },
  methods: {
		//切换遮罩
		onToggle() {
			this.showToggle = !this.showToggle;
		},
		//显示监控标记组件
		showTowerSigns(data){
			let obj = data?.key || {}
			if(obj.type === "towerCrane"){
				this.show = obj.show
			}
		},
		//随机
		doInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
      this.interval = setInterval(() => {
        this.randomValue = this.generateRandom();
      }, 18000);
    },
		/**
     * @desc: 生成随机数
     */
    generateRandom() {
      let min = 0
			let max = 360
			let floatLength = 0 
      const val = Math.random() * max;
      if (val < min ) {
        return this.generateRandom();
      }
      return val.toFixed(isNaN(floatLength) ? 0 : floatLength);
    }
  },
	
  beforeDestroy() {
    clearInterval(this.interval)
    this.interval = null;
		eventBus.$off('doComponentBehavior');
  }
};
</script>

<style lang="less" scoped>
	.tower-sign{
		width: 32px;
		height: 32px;
		background: #fff;
		border-width: 4px;
		border-style: solid;
		border-radius: 50%;
		z-index: 10;
		margin:auto;
		position:relative;
		i{
			font-size: 30px;
			z-index: 10;
		}
		.trangle{
			width: 0px;
			height: 0px;
			border-width: 12px;
			border-style: solid;
			border-bottom-color: transparent;
			border-left-color: transparent;
			border-right-color: transparent;
			position: absolute;
			bottom: -24px;
			left: 4px;
			z-index: 10;
		}
	}
	.circle-cont{
			position: absolute;
			z-index: 20;
			border:4px solid #fff;
			border-radius: 50%;
			overflow: hidden;
			.line-fixed{
				width: 50%;
				height: 2px;
				position: absolute;
				top:50%;
				left:50%;
				border-radius: 8px;
			}
			.line-move{
				position: absolute;
				top:50%;
				right:50%;
				width: 50%;
				height: 2px;
				transform-origin:100% 0%;
				border-radius: 8px;
			}
			.deg-num{
				position: absolute;
				top:58%;
				left:50%;
				font-weight:border;
				font-size: 20px;
			}
			.dot{
				position: absolute;
				top: 50%;
				left: 50%;
				width: 4px;
				height: 4px;
				margin-top:-1px;
				margin-left: -2px;
				border-radius:50%;
			}
	}
	.mask-btn{
		position: absolute;
		padding: 5px;
		font-size: 14px;
		z-index: 30;
		color: @theme;
		right: 0;
		top: 0;
		cursor: pointer;
	}
	.mask{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: transparent;
		line-height: 100%;
		color: #fff;
		z-index: 20;
	}
</style>