<template>
  <dv-decoration-10 class="decoration-container" :color="color" :dur="dur"></dv-decoration-10>
</template>
<script>
import { decoration10 } from '@jiaminghi/data-view'
import Vue from 'vue'
import Minin from './mixin'
Vue.use(decoration10)
export default {
  name: 'Decoration10',
  mixins: [Minin]
}
</script>
<style lang="less" scoped>
  .decoration-container{
    width: 100%;
    height: 100%;
  }
</style>