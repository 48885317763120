<template>
  <div class="app-download" v-loading="loading">
    <p class="info" v-if="infoShow">请跳转至默认浏览器打开本链接</p>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data(){
    return{
      infoShow: false,
      loading: false,
    }
  },
  methods:{
    isIos(){
      const agent = navigator.userAgent;
      const isiOS = !!agent.match(/iPhone|mac|iPod|iPad|ios/i);
      return isiOS
    },
    handleDownload(url) {
      if(this.isIos()) {
        const ua = navigator.userAgent.toLowerCase()
        // 判断环境
        if(ua.match(/MicroMessenger/i)=="micromessenger") {
          this.infoShow = true;
        } else {
          if(url) {
            window.location.href = url;//打开下载的地址
          }
        }
      } else {
        // 下载安卓apk
        if(url) {
          // window.open(url);//打开下载的地址
          window.location.href = url
        }
      }
    },
    getDownloadUrl(){
      this.loading = true;
      const postUrl = `${process.env.VUE_APP_BASE_URL}api/app/getApp`
      axios.post(postUrl).then(res => {
        this.loading = false;
        if(res && +res.data.code === 200){
          const data = res.data.data;
          this.handleDownload(data);
        }else{
          if(res.data.msg){
            this.$message.error(res.data.msg);
          }
          this.handleDownload();
        }
      }).catch(err=>{
        console.log(err);
        this.loading = false;
        this.$message.error("出错了");
      })        
    }
  },
  mounted(){
    this.getDownloadUrl();
  }
}
</script>
<style lang="less" scoped>
  .app-download{
    height: 100%;
    width: 100%;
  }
</style>