<!--
 * @Author: zqy
 * @Date: 2021-12-07 15:06:23
 * @LastEditTime: 2021-12-07 18:21:50
 * @Description:
-->
<!--
    @name: FlowControl
    @description：FlowControl
    @author: ZengWei
    @date: 2021-10-22 09:06
-->
<template>
  <div>
    <el-button-group>
      <el-button plain size="small" @click="btnEvent('openConfig')">
        流程配置
      </el-button>
      <el-button plain size="small" @click="btnEvent('resetZoom')">
        还原(大小&定位)
      </el-button>
      <!--<el-button plain size="small" @click="btnEvent('openTour')">
        新手引导
      </el-button>-->
      <el-button plain size="small" @click="btnEvent('saveFlow')">
        保存
      </el-button>
      <!--<el-button plain size="small" @click="btnEvent('storeDemo')">
        demo
      </el-button>-->
      <!--<el-button plain size="small" @click="$_download">下载图片</el-button>
      <el-button plain size="small" @click="$_catData">查看数据</el-button>-->
      <!--<el-button plain size="small" @click="btnEvent('saveTemp')">
        保存模板
      </el-button>
      <el-dropdown>
        <el-button size="small" type="primary">
          模板选择
          <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>流程模板1</el-dropdown-item>
            <el-dropdown-item>流程模板2</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>-->
    </el-button-group>
  </div>
</template>

<script>
	/*eslint-disable*/
	import {
		Button,
		ButtonGroup,
	} from 'element-ui'
  export default{
    name: 'Control',
	  components: {
		  'el-button-group': ButtonGroup,
		  'el-button': Button
	  },
    props: {},
    data() {
      return {}
    },
    methods: {
      btnEvent(eventName) {
        this.$emit('trigger-event', eventName)
      },
    },
  }
</script>
<style scoped></style>
