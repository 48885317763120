<!--
 * @Author: xjq damarkday@163.com
 * @Date: 2022-06-28 17:44:08
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-28 17:58:57
 * @Description: 
-->
<template>
	<div class="wrap" @click="jumpTo" v-if="statusConfig">
		<div class="main">
			<div class="img-container">
				<img :src="statusConfig.iconUrl" class="icon" :alt="statusConfig.title">
			</div>
			<div class="label-container">
				<p class="title">{{ statusConfig.title || '--' }}</p>
				<p class="description">{{ statusConfig.description }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import cardMixin from './cardMixin';

export default {
	name: 'MiniCard',
	mixins: [cardMixin],
	props: {
		element: {
			type: Object,
			required: true.valueOf,
			default: () => { }
		}
	},
	computed: {
		statusConfig() {
			return this.element?.statusConfig || {};
		}
	}
};
</script>

<style lang="less" scoped>
.wrap {
	background: #ffffff;
	border-radius: 11px;
	padding: 16px;
	box-sizing: border-box;
	cursor: pointer;

	.main {
		display: flex;

		.img-container {
			.icon {
				width: 46px;
				height: 46px;
			}
		}

		.label-container {
			padding: 0 15px;

			.title {
				display: flex;
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 5%;
				color: #272829;
			}

			.description {
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 18px;
				letter-spacing: 0.05em;
				width: 125px;
				height: 18px;
				color: #888888;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}


	}
}
</style>