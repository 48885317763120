<!--
 * @Description: 页面解析器
 * @Author: luocheng
 * @Date: 2021-09-13 15:48:38
 * @LastEditors: luocheng
 * @LastEditTime: 2021-11-16 14:29:32
-->
<template>
  <div class="parser" :style="{
    height: pageConfig.height + 'px',
    width: pageConfig.width + 'px',
  }">
    <RegularPage v-if="pageType === 'page'" :pageData="pageData" :pageId="pageId"></RegularPage>
  </div>
</template>

<script>
import RegularPage from './page/Index';

export default {
  name: 'Parser',
  components: {
    RegularPage
  },
  props: {
    // 页面类型
    pageType: {
      type: String,
      default: '',
      required: true
    },
    // 页面数据
    pageData: {
      type: Array,
      default: () => [],
      require: true
    },
    // 页面样式配置
    pageConfig: {
      type: Object,
      default: () => {},
      required: true
    },
    // 页面样式配置
    pageId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {}
  },
  created() {
    // console.log(this.pageId, 'pageConfigpageConfigpageConfig')
  }
}
</script>

<style lang="less" scoped>
.parser{
  height: 100%;
  width: 100%;
  overflow: auto;
}
</style>