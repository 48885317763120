<!--
 * @Description: 整数输入框
 * @Author: luocheng
 * @Date: 2021-09-24 10:41:53
 * @LastEditors: luocheng
 * @LastEditTime: 2021-09-24 11:08:03
-->
<template>
  <el-input v-model="bindValue" :placeholder="placeholder"
  @input="onInput"
  ></el-input>
</template>

<script>
export default {
  name: 'IntergerInput',
  props: {
    value: {
      required: true,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: '请输入'
    }
  },
  data() {
    return {
      bindValue: ''
    }
  },
  watch: {
    value() {
      this.bindValue = this.value;
    }
  },
  methods: {
    /**
     * @desc: 转整数
     */
    onInput(val) {
      if (isNaN(val)) {
        this.bindValue = '';
      } else if (val.toString().indexOf('.') > -1) {
        this.bindValue = '';
      }
      console.log(this.bindValue, '----');
      this.$emit('input', this.bindValue);
    },
  }
}
</script>

<style>

</style>