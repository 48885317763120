<template>
    <el-card class="ml-main" :data="modelList"  :body-style="{padding : '0px' , height : '100%'}" v-show="show">
        <div class="ml-header">
            <div class="ml-header-title">模型列表</div>
            <i class="close-btn iconfont iconbimgis_guanbi" @click="close()"></i>
        </div>
        <div class="ml-body">
            <el-tree
                ref="tree"
                :data="modelList"
                node-key="modelId"
                :default-checked-keys="defaultChecked"
                show-checkbox
                @check-change="changeModel"
            >
                <template #default="{ node }">
                    <span class="custom-tree-node">
                      <span v-text="node.label"></span>
                      <span>
                        <i v-show="node.checked" class="iconfont iconbim_bumanshitu" @click="fitToView(node.data.modelId)"></i>
                      </span>
                    </span>
                </template>
            </el-tree>
        </div>
    </el-card>
</template>

<script>
    import { Card ,Tree} from "element-ui";
    export default {
        name: "BIMViewerModelList",
        data (){
            return {
                show : false,
                modelList : [],
                defaultChecked : [],
            }
        },
        components: {
            "el-card" : Card,
            "el-tree" : Tree,
        },
        methods : {
            refresh( list , defaultChecked = []){
                this.modelList = list;
                this.defaultChecked = defaultChecked
            },
            open(){
                this.show = true;
            },
            close(){
                this.show = false;
            },
            switch(){
                if(this.show){
                    this.close();
                }else{
                    this.open();
                }
            },
            changeModel ( { modelId } , checked ){
                if(!checked){
                    this.$emit("unloadModel" ,  modelId);
                }else{
                    const node = this.$refs.tree.getNode(modelId);
                    node.data.disabled = true;

                    this.$emit("loadModel" ,   modelId , ()=>{
                        const node = this.$refs.tree.getNode(modelId);
                        node.data.disabled = false;
                        this.$refs.tree.setCurrentKey(modelId);
                    });
                }
            },
            fitToView( mid ) {
                this.$emit('fitToView' , mid);
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
    .ml-main{
        position: absolute;
        top : 20px;
        right : 10px;
        z-index: 999;
        width: 260px;
        height: 90%;
    }
    .ml-header{
        height :  39px;
    }
    .ml-header-title{
        font-size: 18px;
        padding: 10px;
        border-bottom: 1px solid #d0d0d0;
    }
    .ml-body{
        height: calc( 100% - 39px);
        padding : 4px
    }
    .close-btn{
        position: absolute;
        right: 12px;
        top: 12px;
    }
</style>