<!--
    @name: ques-indefinite
    @description：ques-indefinite
    @author: ZengWei
    @date: 2022-04-01 11:18
-->
<template>
  <div class="widget-person">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="seq-num">
        <span v-if="element.config.__config__.required" class="required">*</span>
      </div>
      <div class="q-title">
        <span class="ques-type">[人员信息]</span>
        {{ element.config.__config__.label }}
      </div>
      <div
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc"
        class="q-title">
      </div>
    </div>
    <div class="q-person">
      <el-form
        ref="rowFormRef"
        :model="element.value"
      >
        <template v-for="(item, sindex) in element.config.__slot__.subQuest">
          <div :key="sindex" class="option-item-li draggable-ele">
            <el-form-item
              :prop="item.key"
              :rules="item.rule"
              label-width="0px"
            >
              <div class="option-operate-wrap">
                <div class="richTextEdit">
                  <div>
                    <span>
                      {{ item.label }}
                    </span>
                  </div>
                  <span class="title-svg">
                    <span v-if="item.required" class="required">*</span>
                  </span>
                </div>
              </div>
              <div class="input-arae">
                <el-input
                  v-model="element.value[item.key]"
                  v-if="item.type === 'text'"
                  style="width: 300px"
                  size="small"
                  placeholder="请输入..."
                >
                  <i slot="prefix" class="iconfont input--icon" :class="item.icon" />
                </el-input>
                <el-radio-group v-model="element.value[item.key]" v-else-if="item.type === 'radio'">
                  <el-radio label="1">
                    男
                  </el-radio>
                  <el-radio label="2">
                    女
                  </el-radio>
                </el-radio-group>
                <Uploader
                  v-else-if="item.type === 'upload'"
                  :files="element.value[item.key] || []"
                  @extra-files="(res) => { element[item.key] === res}"
                >
                </Uploader>
              </div>
            </el-form-item>
          </div>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
import quesWidgetMinix from "@/custom-component/form/quesParser/scripts/quesWidgetMinix";
import Uploader from "@/components/fileUpload/newFiles/index";
import getItemRules from '@/custom-component/form/quesParser/scripts/personValidRules.js'
export default {
  name: "ques-person",
  mixins:[quesWidgetMinix],
  props: ['element','number'],
  components: {
    Uploader
  },
  data() {
    return {}
  },
  created() {
    this.initValue()
  },
  methods: {
    initValue() {
      this.element.config.__slot__.subQuest.map(el => {
        el.rule = getItemRules(el)
      })
      if (this.element.value && Object.prototype.toString.call(this.element.value) === '[Object Object]') {
        // nothing to do
      } else {
        this.$set(this.element, 'value', {})
      }
    },
    calcScore() {

    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/questionItem';
.widget-person{
  .q-person{
    .option-item-li {
      margin-top: 6px;
      list-style: none;
      .option-operate-wrap{
        position: relative;
        box-sizing: border-box;
        padding: 0 152px 0 24px;
        width: 100%;
        .richTextEdit{
          padding: 6px 10px 6px 38px;
          color: rgb(72, 72, 72);
          font-size: 14px;
          line-height: 1.4;
          position: relative;
          border:1px solid transparent;
          .right-answer{
            color: #00bf6f;
          }
          .title-svg {
            position: absolute;
            width: 16px;
            height: 16px;
            left: 14px;
            top: 5px;
            .required {
              color: red;
              display: inline-block;
              font-size: 17px;
              margin-right: 2px;
            }
          }
        }
        .option-operate-left {
          width: 24px;
          left: 0;
        }
        .option-operate-right {
          width: 152px;
          right: 0;
          i{
            margin-left: 10px;
            color: red;
          }
        }
        .option-operate-com {
          position: absolute;
          height: 100%;
          top: 4px;
          display: none;
        }
      }
      .input-arae{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:  5px 5px 5px 60px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
