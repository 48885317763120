<template>
  <dv-decoration-11 class="decoration-container" :color="color" :dur="dur">
    <template v-if="textContent">
      <span :style="style">{{ textContent }}</span>
    </template>
  </dv-decoration-11>
</template>
<script>
import { decoration11 } from '@jiaminghi/data-view'
import Vue from 'vue'
import Minin from './mixin'
Vue.use(decoration11)
export default {
  name: 'Decoration11',
  mixins: [Minin]
}
</script>
<style lang="less" scoped>
  .decoration-container{
    width: 100%;
    height: 100%;
  }
</style>