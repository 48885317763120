<!--
 * @Description: 筛选项
 * @Author: luocheng
 * @Date: 2022-05-31 15:50:50
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-30 15:39:40
-->
<template>
  <div class="filter-select">
    <el-select v-model="typeId" @change="onEmit" placeholder="请选择分项">
      <el-option v-for="item in filterList"
        :key="item.id"
        :label="item.rule_name"
        :value="item.id"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data'

export default {
  name: 'FilterSelect',
  props: {
    filterKey: {
      type: [Number, String],
      default: ''
    },
    paramsResult: {
      required: true,
    }
  },
  data() {
    return {
      filterList: [],
      loading: false,
      typeId: ''
    }
  },
  created() {
    this.getFilters();
    this.typeId = this.filterKey;
  },
  watch: {
    paramsResult: {
      handler() {
        this.getFilters(this.paramsResult);
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    /**
     * @desc: 提交
     */
    onEmit() {
      this.$emit('selectKey', this.typeId);
    },
    /**
     * @desc: 获取筛选项列表
     */
    getFilters(params = {}) {
      const { param = {}, search = [], canPost = true } = params;
      console.log('获取筛选项列表', params)
      if (!canPost) return false;
      this.loading = true;
      dataInterface({
        __method_name__: 'dataList',
        view_uuid: 'view6257d0268d93d',
        object_uuid: 'object62426410f3cc4',
        ...param,
        search,
        transcode: 0
      }).then(res => {
        if (res.status === 200 && res.data?.code === 200) {
          this.filterList = res.data.data || [];
          // 测试数据
          this.typeId = this.filterList?.[0]?.id || '';
          this.onEmit();
          this.$emit('updateList', this.filterList);
        }
        this.loading = false;
      }).catch(err => {
        console.log(err);
        this.loading = false;
      });
    }
  }
}
</script>

<style lang="less" scoped>
.filter-select{
  z-index: 3000;
  :deep(.el-select) {
    width: auto;
    .el-input{
      width: 140px;
      display: inline-block;
    }
    .el-input__inner{
      background: transparent;
      border: none;
      color: #fff;
    }
    i {
      // display: none;
      color: #fff;
    }
  }
}
</style>