<!--
    @name: QuestionItem
    @description：QuestionItem
    @author: ZengWei
    @date: 2022-04-01 11:22
-->
<template>
  <div>
    <component
      :is="getWidgetName(element)"
      :element="element"
      :number="number"
    />
  </div>
</template>

<script>
import QuesComponents from '@/custom-component/form/quesParser/components/widget'

export default {
  name: "QuestionItem",
  components: {
    ...QuesComponents
  },
  props: ['element','number'],
  data() {
    return {}
  },
  methods: {
    getWidgetName(widget) {
      return `ques-${widget.config.__ques__.quesType}`
    },
  },
}
</script>

<style scoped>

</style>
