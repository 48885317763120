<!--
 * @Description: 魔法盒子--3.0盒子
 * @Author: luocheng
 * @Date: 2021-12-10 16:58:58
 * @LastEditors: luocheng
 * @LastEditTime: 2022-01-18 09:23:38
-->
<template>
	<div class="common-magic">
		<!-- {{magicUrl}} --------- -->
		<iframe
			v-if="magicUrl"
			:src="magicUrl"
			frameborder="0"
			class="magic-iframe"
		></iframe>
    <!-- <section class="mask"></section> -->
		<section class="magic-iframe">
			<el-empty description="出错了"></el-empty>
		</section>
	</div>
</template>

<script>
import { Empty } from 'element-ui';
import { getToken } from '@/utils/tools';

export default {
	name: 'CommonMagic',
	components: {
		'el-empty': Empty,
	},
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
	computed: {
		// 配置
		magicConfig() {
			return (
				this.element?.magicConfig || {
					defaultHost: '',
					link: ''
				}
			);
		},
		// 链接
		magicUrl() {
			if (!this.magicConfig) return null;
			const { defaultHost, link } = this.magicConfig;
			let host = defaultHost || process.env.VUE_APP_V3_HOST;
			// console.log( `${host}/pc/transit_out?token=${getToken()}&path=${link}`,'9999999999')
			if (!host || !link || !getToken()) return
			// https://crt15.bimcc.net/pc/transit_out?token=c078bfaf49083cf27f7594f244e25101&path=/pc/newHome
			return `${host}/pc/transit_out?token=${getToken()}&path=${link}`;
		}
	},
};
</script>

<style lang="less" scoped>
.common-magic {
	height: 100%;
	width: 100%;
	.magic-iframe {
		display: block;
		height: 100%;
		width: 100%;
		overflow: hidden;
	position: relative;
	.el-empty{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	}
}
</style>