
<template>
  <dv-digital-flop :key="key" :config="config"></dv-digital-flop>
</template>
<script>
import { digitalFlop } from '@jiaminghi/data-view'
import Vue from 'vue'
import Minin from './mixin'
Vue.use(digitalFlop)
export default {
  name: 'FlopCom',
  mixins: [Minin],
  data () {
    return {
      key: this.getKey()
    }
  },
  watch: {
    // 监听大小变化刷新当前视图
    'element.style.width': {
      handler() {
        this.key = this.getKey()
      }
    },
    'element.style.height': {
      handler() {
        this.key = this.getKey()
      }
    },
  },
  computed: {
    config() {
      return {
        number: [isNaN(this.element.propValue) ? 0 : Number(this.element.propValue)],
        content: "{nt}" + this.content,
        textAlign: this.textAlign,
        animationFrame: Number(this.animationFrame),
        toFixed: 0,
        style: {
          fontSize: Number(this.fontSize.replace('px', '')),
          fill: this.element.style.color || '#fff'
        },
        formatter : (number) => {
          if(this.separator){
            return this.formatter(number)
          }else{
            return number
          }
        }
      }
    }
  },
  methods: {
    // 分隔符格式化方法
    formatter (number) {
      const numbers = number.toString().split('').reverse()
      const segs = []
      while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
      return segs.join(',').split('').reverse().join('')
    },
    // 获取key
    getKey() {
      var d = new Date().getTime()
      if (window.performance && typeof window.performance.now === "function") {
          d += performance.now() //use high-precision timer if available
      }
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (d + Math.random() * 16) % 16 | 0   // d是随机种子
          d = Math.floor(d / 16)
          return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
      return uuid
    }
  }
}
</script>