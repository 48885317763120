<!--
 * @Author: your name
 * @Date: 2022-04-18 14:26:36
 * @LastEditTime: 2022-08-26 14:31:57
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \knock-viewer\src\fn-components\bimViewer\player.vue
 萤石云文档地址：http://open.ys7.com/doc/zh/uikit/uikit_javascript.html
-->
<template>
  <div  class="player-container">
		<div class="logo-cont">
				<img src="@/assets/images/gzb_logo.png" alt="">
		</div>
		<div class="bottom-cont">
			<div id="bottomCont" class="w-full h-full" />
			<i class="iconfont iconc-close absolute close" @click="closeMonitor" ></i>
			<i class="iconfont icondaping-quanping full-screen absolute" @click="handleFullscreen"></i>
		</div>
  </div>
</template>
<script>
import { EZUIPlayer } from '@/libs/ezuikit.js'
export default {
	name:'player',
  props: {
    playerType: {
      type: String,
      default: ''
    },
    playerItem: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
			player: null,
    }
  },
  beforeUnmount () {
		if(this.player!=null){
			this.player.stop()
		}
  },
  mounted () {
		if(this.playerItem!==null){
			console.log(this.playerItem,'playerItem')
			this.initPlayer()
		}
  },
  methods: {
    initPlayer () {
			const videoObj = document.createElement('video')
			const source = document.createElement('source')
			videoObj.style = 'width: 100%; height: 100%;object-fit: fill;'
			videoObj.setAttribute('autoplay', 'autoplay')
			videoObj.setAttribute('playsInline', true)
			videoObj.setAttribute('webkit-playsinline', true)
			videoObj.muted = true
			if (this.$props.playerType === 'ys7') {
				source.src = this.playerItem.monitor_path
				source.setAttribute('type', "application/x-mpegURL")
				videoObj.appendChild(source)
				this.player = new EZUIPlayer(videoObj)
				document.getElementById('bottomCont').appendChild(videoObj)
			} else {
				//其他格式待开发
			}
    },
		closeMonitor(){
			this.player.stop()
			this.$emit('closeMonitor')
		},
		handleFullscreen (){
			let main = document.getElementById('bottomCont')
			window.addEventListener('keydown', function(event) {
					//禁掉F11的全屏的默认事件,不会禁止F11的退出全屏
					const e = event || window.event
					if (e && e.keyCode === 122) {
						e.preventDefault()
					}
			})
			if (main.requestFullscreen) {
				main.requestFullscreen()
			} else if (main.mozRequestFullScreen) {
				main.mozRequestFullScreen()
			} else if (main.webkitRequestFullScreen) {
				main.webkitRequestFullScreen()
			} else if (main.msRequestFullscreen) {
				main.msRequestFullscreen()
			}
		}
  },
	destroyed: function () {
    this.player.stop()
  }
}
</script>

<style lang="less"  scoped>
.player-container {
  height: 186px;
  width: 228px;
	position: absolute;
	top: -200px;
	left: -100px;
	border: 2px solid #0068B7;
	background: rgba(0, 0, 0, 0.4);
	.logo-cont{
		height: 22px;
		text-align: left;
		position:relative;
		padding:8px;
		line-height: 22px;
		border-bottom: 4px solid #0068B7;
		img{
			width: 60px;
			height: 100%;
		}
	}
	.logo-cont:after{
		content: "";
		height: 4px;
		width: 88px;
		position: absolute;
		bottom:-4px;
		left: 0;
		background-color: #E80013;
	}
	.bottom-cont{
		position:relative;
		margin:8px;
		height: calc(100% - 60px);
		.close{
			position:absolute;
			top: 2px;
			right: 5px;
			font-size: 22px;
			color:#fff;
		}
		.full-screen{
			position:absolute;
			top: 5px;
			right:30px;
			color:#fff;
		}
	}
	
	:deep(.iframe-btn-container) {
		padding:0!important;
		svg{
			width: 16px!important;
			height: 16px!important;
		}
	}
}
</style>
