
<template>
  <dv-border-box-3 class="border-box-container" :color="color">
    <template v-if="textContent">
      <span :style="style">{{ textContent }}</span>
    </template>
  </dv-border-box-3>
</template>
<script>
import { borderBox3 } from '@jiaminghi/data-view'
import Vue from 'vue'
import Minin from './mixin'
Vue.use(borderBox3)
export default {
  name: 'Border3',
  mixins: [Minin]
}
</script>
<style lang="less">
  .border-box-container{
    width: 100%;
    height: 100%;
    .border-box-content{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>