
<template>
  <dv-border-box-11 class="border-box-container" :title="title" :titleWidth="titleWidth" :color="color">
    <template v-if="textContent">
      <span :style="style">{{ textContent }}</span>
    </template>
  </dv-border-box-11>
</template>
<script>
import { borderBox11 } from '@jiaminghi/data-view'
import Vue from 'vue'
import Minin from './mixin'
Vue.use(borderBox11)
export default {
  name: 'Border11',
  mixins: [Minin]
}
</script>
<style lang="less">
  .border-box-container{
    width: 100%;
    height: 100%;
    .border-box-content{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>