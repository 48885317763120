<template>
  <dv-decoration-12 :key="key" class="decoration-container" :color="color" :scanDur="scanDur" :haloDur="haloDur"></dv-decoration-12>
</template>
<script>
import { decoration12 } from '@jiaminghi/data-view'
import Vue from 'vue'
import Minin from './mixin'
Vue.use(decoration12)
export default {
  name: 'Decoration12',
  mixins: [Minin],
  data() {
    return {
      key: this.getKey()
    }
  },
  watch: {
    // 监听大小变化刷新当前视图
    'element.style.width': {
      handler() {
        this.key = this.getKey()
      }
    },
    'element.style.height': {
      handler() {
        this.key = this.getKey()
      }
    }
  },
  methods: {
    getKey() {
      var d = new Date().getTime()
      if (window.performance && typeof window.performance.now === "function") {
          d += performance.now() //use high-precision timer if available
      }
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (d + Math.random() * 16) % 16 | 0   // d是随机种子
          d = Math.floor(d / 16)
          return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
      return uuid
    }
  }
}
</script>
<style lang="less" scoped>
  .decoration-container{
    width: 100%;
    height: 100%;
  }
</style>